import React from 'react';
import styled from 'styled-components';
import noOfferImage from '../images/welcome-3.svg';
import { CenteredContainer } from './layout/styled';
import { shortDeviceMaxHeight } from '../shared/constants';

const StyledImg = styled.img`
  @media (max-width: 360px) {
    width: 70% !important;
  }
  @media (max-height: ${shortDeviceMaxHeight}) {
    max-height: 30%;
  }
`;

const StyledH2 = styled.h2`
  @media (max-height: ${shortDeviceMaxHeight}) {
    margin: 0;
  }
`;

const StyledP = styled.p`
  @media (max-height: ${shortDeviceMaxHeight}) {
    line-height: 1;
  }
`;

export const NoOffersEmptyState = () => {
  return (
    <CenteredContainer>
      <StyledImg src={noOfferImage} alt="No Offers" />
      <StyledH2>No Offers Available</StyledH2>
      <StyledP>
        Check back soon for more chances to get rewarded for supporting our
        local business!
      </StyledP>
    </CenteredContainer>
  );
};

export default NoOffersEmptyState;
