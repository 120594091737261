import React from 'react';
import styled from 'styled-components';
import { CenteredContainer } from './layout/styled';
import { shortDeviceMaxHeight } from '../shared/constants';
import AddMoreBusinesses from './AddMoreBusinesses';

const EmptyStateHeader = styled.h1`
  font-size: 22px;
  font-weight: 800;

  @media (max-height: ${shortDeviceMaxHeight}) {
    margin: 10px 0;
  }
`;

const EmptyStateText = styled.p`
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 1em;
  color: var(--ion-color-medium);

  // desktop
  @media (min-width: 760px) {
    width: 350px;
  }

  @media (max-height: ${shortDeviceMaxHeight}) {
    line-height: 1;
    margin: 0;
  }
`;

const EmptyStateImg = styled.img`
  ::part(image) {
    width: 280px;
    display: inline;
    margin-bottom: 40px;
  }

  @media (max-width: 360px) {
    width: 80% !important;
  }

  @media (max-height: ${shortDeviceMaxHeight}) {
    max-height: 30%;
  }
`;

interface Props {
  image: string;
}

export const NoStoresEmptyState: React.FC<Props> = ({ image }) => {
  return (
    <CenteredContainer>
      <EmptyStateImg src={image} />
      <EmptyStateHeader>No businesses found</EmptyStateHeader>
      <EmptyStateText>
        Sorry, we weren’t able to find any businesses where you shop that have
        savings available through Plink. Click the button below to find new
        businesses where you can save.
      </EmptyStateText>
      <AddMoreBusinesses button />
    </CenteredContainer>
  );
};

export default NoStoresEmptyState;
