import { usePatch, usePost } from '../fetch/fetch-resolver';
import { useAppState } from '../../shared/AppContext';
import { setAuth } from './auth-actions';
import {
  Auth,
  LoginRequest,
  ResetPasswordLinkRequest,
  ResetPasswordRequest
} from './auth-types';

export const useLoginAccessToken = (accessToken: string) => {
  const url = 'consumers/login';
  return usePost({
    url,
    version: 'v2',
    initialData: { accessToken },
    action: setAuth
  });
};

export const useLogin = () => {
  const url = 'consumers/login';
  return usePost<LoginRequest>({ url, version: 'v2', action: setAuth });
};

export const useAuth = (): Auth => {
  const { state } = useAppState();
  return state.auth;
};

export const useRequestResetPassword = () =>
  usePost<ResetPasswordLinkRequest>({
    url: 'consumers/reset',
    version: 'v2'
  });

export const useResetPassword = () =>
  usePatch<ResetPasswordRequest>({
    url: 'consumers/reset',
    version: 'v2'
  });
