import { FetchError } from '../features/fetch/fetch-types';

const messagesByErrorId: { [key: string]: string } = {
  DUPLICATE_CONSUMER_CARD:
    "You've already added this credit card. Please try a different one.",
  CANCELED_CONSUMER_CARD:
    'Your card is no longer valid, please enter new card information.',
  COLLINSON_CREATE_ACCOUNT_ERROR:
    'There was an error linking your card. If the issue persists, please contact us at support@fanbank.com.',
  COLLINSON_GET_ACCOUNTS_ERROR:
    'There was an error linking your card. If the issue persists, please contact us at support@fanbank.com.',
  CREDIT_CARD_SALE_ERROR:
    'There was an error processing your payment. Please try again or contact us at support@fanbank.com.',
  DEFAULT_ERROR_CODE:
    'There was a problem processing your request. Please contact us at support@fanbank.com.'
};

export const parseAPIError = (err: FetchError): string => {
  if (!err.errorCode) return messagesByErrorId.DEFAULT_ERROR_CODE;

  return messagesByErrorId[err.errorCode] || err.message;
};
