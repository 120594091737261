import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonBadge,
  IonLabel,
  IonSpinner
} from '@ionic/react';
import { Redirect, Route } from 'react-router';
import {
  home,
  wallet,
  star,
  ellipsisHorizontal as ellipsis
} from 'ionicons/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CenteredContainer } from '../components/layout/styled';
import HomePage from './HomePage';
import WalletPage from './WalletPage';
import RewardsPage from './rewards/RewardsPage';
import MorePage from './MorePage';
import { StorePage } from './StorePage';
import StoreInfoPage from './StoreInfoPageV1';
import { CampaignPage } from './CampaignPage';
import FindBusinessPage from './FindBusinessPage';
import FAQsPage from './FAQsPage';
import HowPlinkWorksPage from './HowPlinkWorksPage';
import NominateBusinessPage from './NominateBusinessPage';
import HowFancoinsWorkPage from './HowFancoinsWorkPage';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import AccountInfoPage from './AccountInfoPage';
import PrivateRoute from '../components/PrivateRoute';
import { useWalletNotifications } from '../features/notifications/notifications-resolver';
import {
  useGetConsumerStores,
  usePostConsumerStore
} from '../features/consumer-stores/consumer-stores-resolver';
import { routes } from '../shared/constants';
import ConsumerNamePage from './ConsumerNamePage';
import ConsumerEmailPage from './ConsumerEmailPage';
import ConsumerPasswordPage from './ConsumerPasswordPage';
import ConsumerBirthdayPage from './ConsumerBirthdayPage';
import { useAuth } from '../features/auth/auth-resolver';
import { useURLQuery } from '../features/query/query-resolver';
import { ConsumerStoreRequest } from '../features/consumer-stores/consumer-stores-types';
import { useAppState } from '../shared/AppContext';
import { FetchError } from '../features/fetch/fetch-types';
import { LinkAccountPage } from './LinkAccountPage';

const TabsPage: React.FC = () => {
  const { dispatch } = useAppState();
  const { first: showWalletBadge } = useWalletNotifications();
  const [{ data: consumerStores }, getConsumerStores] = useGetConsumerStores();
  const auth = useAuth();
  const { storeURLQuery, readURLQueryFromAppState } = useURLQuery();
  const [
    { isLoading: isLoadingEngagement },
    doConsumerStoreEngagement
  ] = usePostConsumerStore();

  const postConsumerStoreEngagement = async (values: ConsumerStoreRequest) => {
    const result = await doConsumerStoreEngagement(values);
    if (result && !(result as FetchError).errorCode) {
      dispatch(getConsumerStores());
    }
  };

  useEffect(
    () => {
      // storing URL query params if exists.
      storeURLQuery();

      // if user is logged in and there is store context, post to engagement
      const { storeId, campaignId, businessId } = readURLQueryFromAppState();
      if (!isEmpty(auth) && storeId) {
        postConsumerStoreEngagement({
          businessId: businessId ? Number(businessId) : undefined,
          campaignId: campaignId ? Number(campaignId) : undefined,
          consumerId: auth.consumerId,
          storeId: Number(storeId)
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { appV2 } = useFlags();
  const tabButtonClass = appV2 ? 'tab-button' : '';

  return (
    <>
      {isLoadingEngagement ? (
        <CenteredContainer>
          <IonSpinner title="page-spinner" name="crescent" />
        </CenteredContainer>
      ) : (
        <IonTabs>
          <IonRouterOutlet>
            <Redirect exact path="/tabs" to="/tabs/home" />
            <PrivateRoute
              path={`/tabs/${routes.home}`}
              component={HomePage}
              exact
            />
            <PrivateRoute
              path={`/tabs/${routes.wallet}`}
              component={WalletPage}
              exact
            />
            <PrivateRoute
              path={`/tabs/${routes.rewards}`}
              component={RewardsPage}
              exact
            />
            <PrivateRoute
              path={`/tabs/${routes.more}`}
              component={MorePage}
              exact
            />
            <PrivateRoute
              path={`/tabs/${routes.storesHome}`}
              component={StorePage}
              exact
            />
            <PrivateRoute
              path={`/tabs/${routes.storeInfoHome}`}
              component={StoreInfoPage}
            />
            <PrivateRoute
              path={`/tabs/${routes.storeCampaignsHome}`}
              component={CampaignPage}
            />

            <PrivateRoute
              path={`/tabs/${routes.storeCampaignsHome}`}
              component={CampaignPage}
              exact
            />

            <PrivateRoute
              path={`/tabs/${routes.storeCampaignsHome}/info`}
              component={StoreInfoPage}
            />

            <PrivateRoute
              path={`/tabs/${routes.walletCampaignsHome}`}
              component={CampaignPage}
              exact
            />

            <PrivateRoute
              path={`/tabs/${routes.walletCampaignsHome}/info`}
              component={StoreInfoPage}
            />

            <Route
              path={`/tabs/${routes.howPlinkWorks}`}
              render={() => (
                <HowPlinkWorksPage backRoute={`/tabs/${routes.more}`} />
              )}
              exact
            />
            <Route
              path={`/tabs/${routes.findBusiness}`}
              render={() => (
                <FindBusinessPage backRoute={`/tabs/${routes.more}`} />
              )}
              exact
            />
            <Route
              path={[
                routes.connectAccountAndReturnRoute,
                routes.connectAccount
              ]}
              render={() => (
                <LinkAccountPage backRoute={`/tabs/${routes.more}`} />
              )}
            />
            <Route
              path={`/tabs/${routes.nominateBusiness}`}
              render={() => (
                <NominateBusinessPage backRoute={`/tabs/${routes.more}`} />
              )}
              exact
            />
            <Route
              path={`/tabs/${routes.faqs}`}
              render={() => <FAQsPage backRoute={`/tabs/${routes.more}`} />}
              exact
            />
            <Route
              path={`/tabs/${routes.howFancoinsWork}`}
              render={() => (
                <HowFancoinsWorkPage backRoute={`/tabs/${routes.more}`} />
              )}
              exact
            />
            <Route
              path={`/tabs/${routes.privacyPolicy}`}
              render={() => (
                <PrivacyPolicyPage backRoute={`/tabs/${routes.more}`} />
              )}
              exact
            />
            <Route
              path={`/tabs/${routes.howPlinkWorksRewards}`}
              render={() => (
                <HowPlinkWorksPage backRoute={`/tabs/${routes.rewards}`} />
              )}
              exact
            />
            <Route
              path={routes.accountInfo}
              render={() => <AccountInfoPage />}
              exact
            />
            <Route
              path={`/tabs/${routes.consumerName}`}
              render={() => <ConsumerNamePage />}
              exact
            />
            <Route
              path={`/tabs/${routes.consumerEmail}`}
              render={() => <ConsumerEmailPage />}
              exact
            />
            <Route
              path={`/tabs/${routes.consumerPassword}`}
              render={() => <ConsumerPasswordPage />}
              exact
            />
            <Route
              path={`/tabs/${routes.consumerBirthday}`}
              render={() => <ConsumerBirthdayPage />}
              exact
            />
          </IonRouterOutlet>
          <IonTabBar className="web-hide" slot="bottom">
            <IonTabButton
              className={tabButtonClass}
              tab="home"
              href={`/tabs/${routes.home}`}
            >
              <IonIcon icon={home} />
              <IonLabel>Home</IonLabel>
            </IonTabButton>
            {!appV2 && (
              <IonTabButton
                className={tabButtonClass}
                tab="wallet"
                href={`/tabs/${routes.wallet}`}
              >
                {showWalletBadge &&
                  consumerStores &&
                  consumerStores.length > 0 && (
                    <IonBadge color="danger">1</IonBadge>
                  )}
                <IonIcon icon={wallet} />
                <IonLabel>Wallet</IonLabel>
              </IonTabButton>
            )}
            <IonTabButton
              className={tabButtonClass}
              tab="rewards"
              href={`/tabs/${routes.rewards}`}
            >
              <IonIcon icon={star} />
              <IonLabel>{!appV2 ? 'Rewards' : 'Savings History'}</IonLabel>
            </IonTabButton>
            <IonTabButton
              className={tabButtonClass}
              tab="more"
              href={`/tabs/${routes.more}`}
            >
              <IonIcon icon={ellipsis} />
              <IonLabel>More</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      )}
    </>
  );
};

export default TabsPage;
