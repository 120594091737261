import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
`;

export const ContainerSecondary = styled(Container)`
  @media (prefers-color-scheme: light) {
    background-color: #efefef;
  }
  @media (prefers-color-scheme: dark) {
    background-color: #1a1a1a;
  }
`;

export const CenteredContainer = styled(Container)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${() => `calc(100vh - 123px)`};

  @media (max-width: 360px) {
    height: ${() => `calc(100vh - 5em)`};
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;
