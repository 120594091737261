import React, { useEffect, useState } from 'react';
import {
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonItem,
  IonThumbnail,
  IonLabel,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRouterLink
} from '@ionic/react';
import styled from 'styled-components';
import NoRewardsEmptyState from '../../components/NoRewardsEmptyState';
import { useAuth } from '../../features/auth/auth-resolver';
import { useGetConsumer } from '../../features/consumer/consumer-resolver';
import iconFancoins from '../../images/icon-fancoins.svg';
import iconSavings from '../../images/icon-savings.svg';
import { routes } from '../../shared/constants';
import { SectionHeader } from '../../components/layout/SectionHeader';
import { ListHeader } from '../../components/layout/ListHeader';
import { useGetTransactions } from '../../features/transactions/transactions-resolver';
import { TransactionItem } from './components/TransactionItem';
import { TransactionType } from '../../features/transactions/transactions-types';
import { formatTransactionType } from '../../utils/format';
import { PageLayout } from '../../components/layout/PageLayout';
import { SavingsHistoryEmptyState } from '../../components/SavingsHistoryEmptyState';
import { useFlags } from 'launchdarkly-react-client-sdk';

const RewardSummarySection = styled.div`
  padding: 20px;
`;

const RewardItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;

  p {
    margin: 0;
    font-size: 20px;
  }

  a {
    font-size: 14px;
    font-weight: bold;
  }
`;

const FancoinsTextWrapper = styled.div`
  margin-left: 1em;
`;

const FancoinAmount = styled.p`
  margin-left: auto !important;
`;

const FancoinRedeemLink = styled(IonRouterLink)`
  font-weight: bold;
  font-size: 85%;
  text-decoration: underline;
  line-height: 2;
`;

const SkeletonRewardItem = styled(IonItem)`
  border-bottom: 1px var(--ion-color-light-shade) solid;
  --inner-border-width: 0px;
  --inner-padding-top: 34px;
  --inner-padding-bottom: 34px;
`;

const RewardThumbnail = styled(IonThumbnail)`
  width: 76px;
  height: 76px;
`;

const renderSkeleton = () => (
  <IonList id="skeleton">
    <IonListHeader>
      <IonSkeletonText animated style={{ width: '70%' }} />
    </IonListHeader>
    {Array.from({ length: 2 }, (a, i) => (
      <SkeletonRewardItem key={i}>
        <RewardThumbnail slot="start">
          <IonSkeletonText animated />
        </RewardThumbnail>
        <IonLabel>
          <h3>
            <IonSkeletonText animated style={{ width: '50%' }} />
          </h3>
          <p>
            <IonSkeletonText animated style={{ width: '70%' }} />
          </p>
        </IonLabel>
      </SkeletonRewardItem>
    ))}
    <IonListHeader>
      <IonSkeletonText animated style={{ width: '70%' }} />
    </IonListHeader>
    {Array.from({ length: 8 }, (a, i) => (
      <SkeletonRewardItem key={i}>
        <IonLabel>
          <div>
            <IonSkeletonText animated style={{ width: '20%' }} />
          </div>
          <div>
            <IonSkeletonText animated style={{ width: '10%' }} />
          </div>
          <div>
            <IonSkeletonText animated style={{ width: '30%' }} />
          </div>
        </IonLabel>
      </SkeletonRewardItem>
    ))}
  </IonList>
);

const RewardsPage: React.FC = () => {
  const { appV2 } = useFlags();
  const auth = useAuth();
  const [
    { data: consumer, isLoading: isConsumerLoading, error: consumerError },
    getConsumer
  ] = useGetConsumer();

  const [
    {
      data: { data: transactions, pagination },
      isLoading: isTransactionsLoading,
      error: transactionsError
    },
    getTransactions
  ] = useGetTransactions({ paginated: true });

  const [page, setPage] = useState(1);

  useEffect(
    () => {
      getConsumer();
      getTransactions();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleRefresh = async () => {
    setPage(1);
    const options = { refresh: true };
    await Promise.all([getConsumer(options), getTransactions(options)]);
  };

  const generateTransactionsList = () => {
    const nodes: React.ReactNode[] = [];
    let currentType: TransactionType;
    transactions.forEach((transaction) => {
      if (currentType !== transaction.type) {
        currentType = transaction.type;
        nodes.push(
          <ListHeader
            key={transaction.type}
            data-testid="transaction-type-list-header"
          >
            {formatTransactionType(transaction.type)}
          </ListHeader>
        );
      }
      nodes.push(
        <TransactionItem key={transaction.id} transaction={transaction} />
      );
    });
    return nodes;
  };

  const redirectUrl = `${process.env.REACT_APP_CONSUMER_PORTAL_URL}/fanstore`;
  const authUrl = `${process.env.REACT_APP_AUTH_URL}?id=${auth.id}&redirectUrl=${redirectUrl}`;

  const loading = isConsumerLoading || (isTransactionsLoading && page === 0);
  const hasRewards = consumer?.earnedFancoins > 0;
  const error = !!transactionsError || consumerError;

  const handleInfinite = async () => {
    if (!isTransactionsLoading) {
      const nextPage = page + 1;
      if (nextPage <= pagination.last) {
        await getTransactions({ refresh: true, params: { page: nextPage } });
        setPage(nextPage);
      }
    }
  };

  return (
    <PageLayout
      error={error}
      loading={loading}
      skeleton={renderSkeleton}
      onRefresh={handleRefresh}
    >
      {hasRewards || (transactions && transactions.length > 0) ? (
        <IonList>
          <SectionHeader href={`tabs/${routes.howPlinkWorksRewards}`}>
            {appV2 ? 'Savings History' : 'My Rewards'}
          </SectionHeader>
          <RewardSummarySection>
            <RewardItem>
              <img src={iconSavings} alt="Savings" />
              <FancoinsTextWrapper>
                <p>Savings</p>
              </FancoinsTextWrapper>
              <FancoinAmount>${consumer.earnedFancoins}</FancoinAmount>
            </RewardItem>
            <RewardItem>
              <img src={iconFancoins} alt="Fancoins" />
              <FancoinsTextWrapper>
                <p>Fancoins</p>
                <FancoinRedeemLink
                  href={authUrl}
                  target="_blank"
                  color="dark"
                  rel="noopener noreferrer"
                >
                  REDEEM NOW
                </FancoinRedeemLink>
              </FancoinsTextWrapper>
              <FancoinAmount>
                {(consumer.earnedFancoins - consumer.redeemedFancoins).toFixed(
                  2
                )}
              </FancoinAmount>
            </RewardItem>
          </RewardSummarySection>
          {transactions && transactions.length > 0 && (
            <>
              <SectionHeader>Transaction History</SectionHeader>
              <IonList>{generateTransactionsList()}</IonList>
              <IonInfiniteScroll
                onIonInfinite={handleInfinite}
                disabled={page >= pagination.last}
              >
                <IonInfiniteScrollContent />
              </IonInfiniteScroll>
            </>
          )}
        </IonList>
      ) : appV2 ? (
        <SavingsHistoryEmptyState />
      ) : (
        <NoRewardsEmptyState />
      )}
    </PageLayout>
  );
};

export default RewardsPage;
