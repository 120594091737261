import React from 'react';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonPage,
  IonContent,
  IonAlert,
  IonItem
} from '@ionic/react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Header, HeaderProps } from '../Header';
import { Refresher } from '../Refresher';

export interface PageLayoutProps extends HeaderProps {
  error?: any;
  loading?: boolean;
  skeleton?: () => React.ReactNode;
  onRefresh?: () => Promise<any>;
  subheader?: () => React.ReactNode;
  showHeader?: boolean;
  fullscreen?: boolean;
  learnMoreText?: string;
  learnMoreRoute?: string;
  footerElement?: React.ReactNode;
}

const headerHeight = '56px';
const footerHeight = '4rem';
const learnMoreHeight = '90px';

const generateFullscreenHeight = (showHeader?: boolean) =>
  showHeader ? '100vh' : `calc(100vh - ${headerHeight})`;

const Grid = styled(IonGrid)<PageLayoutProps>`
  height: ${({ fullscreen, showHeader }) =>
    fullscreen ? generateFullscreenHeight(showHeader) : undefined};
`;

const Row = styled(IonRow)<PageLayoutProps>`
  height: ${({ fullscreen, showHeader }) =>
    fullscreen ? generateFullscreenHeight(showHeader) : undefined};
`;

const Col = styled(IonCol)`
  max-width: 960px !important;
`;

const LearnMoreSection = styled(IonItem)`
  height: ${learnMoreHeight};
  overflow: hidden;
  --background: #efefef;

  @media (prefers-color-scheme: dark) {
    --background: #4a4a4a;
  }

  & > div {
    font-size: 16px;
    width: 100%;
    padding: 20px 0;
    text-align: center;
    text-decoration: underline;
  }
`;

const Content = styled.div<{ hasLearnMore: boolean }>`
  min-height: ${({ hasLearnMore }) =>
    hasLearnMore
      ? `calc(100% - (${footerHeight} + ${learnMoreHeight}))`
      : `calc(100% - ${footerHeight})`};

  @media (max-width: 360px) {
    height: ${() => `calc(100vh - 5em)`};
  }
`;

const WebBackButton = styled(IonItem)`
  display: inline-block;
  cursor: pointer;
  --border-style: none;
  font-size: 13px;
  color: #4e4e4e;
  @media (prefers-color-scheme: dark) {
    color: #ffffff;
  }
`;

const WebFooter = styled.div`
  background: var(--ion-toolbar-background);
  color: #ffffff;
  height: 4rem;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const PageLayout: React.FC<PageLayoutProps> = ({
  error,
  loading = false,
  skeleton,
  children,
  showBack,
  backRoute,
  onRefresh,
  subheader,
  showHeader = true,
  fullscreen,
  learnMoreText,
  learnMoreRoute,
  footerElement
}) => {
  const { goBack } = useHistory();
  const location = useLocation();
  const back = showBack || !!backRoute;
  const isWidget = location.pathname.includes('widget');

  const learnMoreUrl = isWidget
    ? `/widget/${learnMoreRoute}`
    : `/tabs/${learnMoreRoute}`;

  const { appV2 } = useFlags();

  return (
    <IonPage>
      {showHeader && <Header showBack={back} backRoute={backRoute} />}
      <IonContent fullscreen>
        {onRefresh && <Refresher onRefresh={onRefresh} />}

        <Content hasLearnMore={!!learnMoreRoute}>
          {subheader && subheader()}
          <Grid
            className="ion-no-padding"
            fullscreen={fullscreen}
            showHeader={showHeader}
          >
            <Row
              className="ion-justify-content-center"
              fullscreen={fullscreen}
              showHeader={showHeader}
            >
              <Col sizeLg="7">
                {back && !appV2 && (
                  <WebBackButton
                    role="button"
                    onClick={goBack}
                    className="mobile-hide"
                    data-testid="back-button"
                  >{`< Back`}</WebBackButton>
                )}
                {skeleton && loading ? skeleton() : children}
              </Col>
            </Row>
          </Grid>
        </Content>
        {learnMoreRoute && (
          <>
            <LearnMoreSection
              lines="none"
              detail={false}
              routerLink={learnMoreUrl}
            >
              <div>
                <p>{learnMoreText}</p>
              </div>
            </LearnMoreSection>
          </>
        )}

        {footerElement && footerElement}

        {!fullscreen && (
          <WebFooter className="mobile-hide">
            © {DateTime.local().year} Fanbank Inc. All Rights Reserved. Plink
            and Fanbank ® are trademarks of Fanbank, Inc.
          </WebFooter>
        )}
      </IonContent>

      <IonAlert
        isOpen={Boolean(error)}
        cssClass="modal-dark-mode"
        header="Network Error"
        message="We're having an issue loading your data."
        buttons={['OK']}
        data-testid="network-error-modal"
      />
    </IonPage>
  );
};
