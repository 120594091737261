import React from 'react';
import { IonButton } from '@ionic/react';
import styled from 'styled-components';
import noOffer from '../../images/no-offer.svg';
import { useConsumerData } from '../../features/consumer/consumer-resolver';

const OverflowWrapper = styled.div`
  overflow-y: scroll;
`;

const Wrapper = styled.div`
  text-align: center;
  width: 90%;
  margin: 0 auto;
`;

const ModalTitle = styled.h1`
  text-align: center;
  font-size: 22px;
  font-weight: bold;
`;

const SaveText = styled.p`
  margin-top: 0;
  color: var(--description-text);
  font-size: 16px;
`;

const RedeemText = styled.p`
  font-weight: bold;
  font-size: 18px;
`;

const RedeemSteps = styled.div`
  text-align: left;
`;

const RedeemStepInfo = styled.div`
  display: flex;
  align-items: center;
`;

const DisclaimerText = styled.p`
  font-size: 12px;
  color: var(--description-text);
`;

const StyledButton = styled(IonButton)`
  margin: 2em 0;
`;

const RewardText = styled.p`
  font-weight: bold;
`;

const LinkCardFinish: React.FC<{
  onButtonClick: () => void;
}> = ({ onButtonClick }) => {
  const { firstName } = useConsumerData();

  return (
    <OverflowWrapper>
      <Wrapper className="modal-dark-mode">
        <ModalTitle>Congratulations, {firstName}!</ModalTitle>
        <SaveText>You are all set up to save.</SaveText>
        <img src={noOffer} alt="Congratulations Step" />
        <RedeemSteps>
          <RedeemText>How to Redeem</RedeemText>
          <RedeemStepInfo>
            <div className="circle-step">1</div>
            <p>Make qualifying in-store purchases using your linked card</p>
          </RedeemStepInfo>
          <RedeemStepInfo>
            <div className="circle-step">2</div>
            <p>Rewards will be fulfilled after each payment processes</p>
          </RedeemStepInfo>
        </RedeemSteps>
        <RewardText>
          Get rewarded each time you come back and make a purchase.
        </RewardText>
        <StyledButton
          className="modal-button"
          expand="block"
          onClick={onButtonClick}
        >
          DONE
        </StyledButton>
        <DisclaimerText>
          Offers may vary. Limit one registration per person. See your
          confirmation email upon registration completion for more details.
        </DisclaimerText>
      </Wrapper>
    </OverflowWrapper>
  );
};

export default LinkCardFinish;
