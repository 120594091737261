import { Notifications } from './notifications-types';
import { Action } from '../actions/actions-types';
import { notificationsActionTypes } from './notifications-actions';

export const notificationsInitialState = () =>
  ({
    home: { first: true },
    wallet: { first: true }
  } as Notifications);

export const notificationsReducer = (
  state = notificationsInitialState(),
  action: Action<boolean>
): any => {
  switch (action.type) {
    case notificationsActionTypes.SET_ALL_NOTIFICATIONS: {
      return {
        home: { first: action.payload },
        wallet: { first: action.payload }
      };
    }
    case notificationsActionTypes.SET_NOTIFICATION_HOME: {
      return {
        ...state,
        home: { first: action.payload }
      };
    }
    case notificationsActionTypes.SET_NOTIFICATION_WALLET: {
      return {
        ...state,
        wallet: { first: action.payload }
      };
    }
    default:
      return state;
  }
};
