import React from 'react';
import { IonItem } from '@ionic/react';
import styled from 'styled-components';
import { Transaction } from '../../../features/transactions/transactions-types';
import { TransactionItemDetails } from './TransactionItemDetails';
import { TransactionItemRewards } from './TransactionItemRewards';

export interface TransactionItemProps {
  transaction: Transaction;
}

const Container = styled.div`
  padding: 1rem 0;
  display: flex;
  width: 100%;
  align-items: flex-start;
`;

export const TransactionItem: React.FC<TransactionItemProps> = ({
  transaction
}) => (
  <IonItem data-testid="transaction-item">
    <Container>
      <TransactionItemDetails transaction={transaction} />
      <TransactionItemRewards transaction={transaction} />
    </Container>
  </IonItem>
);
