import React, { useState } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonModal,
  IonButton,
  IonButtons,
  IonIcon
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import LinkCardSteps from './LinkCardSteps';
import LinkCardForm from './LinkCardForm';
import LinkCardFinish from './LinkCardFinish';

type LinkCardProps = {
  open: boolean;
  closeModalHandler: (success: boolean, backToPurchaseModal?: boolean) => void;
  backToPurchaseModal?: boolean;
  hasCard?: boolean;
};

const LinkCardModal: React.FC<LinkCardProps> = ({
  open,
  closeModalHandler,
  backToPurchaseModal,
  hasCard
}) => {
  const [step, setStep] = useState(1);

  return (
    <IonModal isOpen={open} backdropDismiss={false} cssClass="modal-dark-mode">
      <IonHeader translucent>
        <IonToolbar className="modal-toolbar">
          <IonButtons slot="primary">
            <IonButton
              fill="clear"
              color="dark"
              onClick={() => closeModalHandler(step === 3, backToPurchaseModal)} // Closing on step 3 is still a success
            >
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      {step === 1 && !hasCard && (
        <LinkCardSteps
          onButtonClick={() => {
            setStep(2);
          }}
        />
      )}
      {(step === 2 || (step === 1 && backToPurchaseModal && hasCard)) && (
        <LinkCardForm
          onButtonClick={() => {
            if (backToPurchaseModal) {
              closeModalHandler(false, backToPurchaseModal);
            } else {
              setStep(3);
            }
          }}
        />
      )}
      {step === 3 && (
        <LinkCardFinish onButtonClick={() => closeModalHandler(true)} />
      )}
    </IonModal>
  );
};

export default LinkCardModal;
