import { Notifications } from './notifications-types';
import { useAppState } from '../../shared/AppContext';

export const useHomeNotifications = (): Notifications['home'] => {
  const { state } = useAppState();
  return state.notifications.home;
};

export const useWalletNotifications = (): Notifications['wallet'] => {
  const { state } = useAppState();
  return state.notifications.wallet;
};
