import {
  IonList,
  IonItem,
  IonListHeader,
  IonLabel,
  IonButton,
  IonSpinner,
  IonText,
  IonAlert,
  IonSkeletonText
} from '@ionic/react';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useHistory } from 'react-router-dom';
import { PageLayout } from '../components/layout/PageLayout';

import {
  useConsumerData,
  useGetConsumer,
  usePatchConsumer
} from '../features/consumer/consumer-resolver';
import TextFieldInput from '../components/form/TextFieldInput';
import { FetchError } from '../features/fetch/fetch-types';

import { updateConsumer } from '../features/consumer/consumer-actions';
import { useAppState } from '../shared/AppContext';
import { routes } from '../shared/constants';

type FormValues = {
  email: 'string';
};

const AccountHeader = styled(IonListHeader)`
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: bold;
  font-size: 18pt;
`;

const AccountList = styled(IonList)`
  padding-right: 20px;
`;

const WarningText = styled.div`
  padding-top: 20px;
  padding-left: 20px;
  font-size: 12px;
`;

const SaveButton = styled(IonButton)`
  margin-left: 20px;
  margin-right: 20px;
`;

const ErrorText = styled(IonText)`
  padding-left: 20px;
`;

const ConsumerEmailPage: React.FC = () => {
  const { dispatch } = useAppState();
  const [
    { error: consumerError, isLoading: isLoadingConsumer },
    getConsumer
  ] = useGetConsumer();
  const consumerData = useConsumerData();
  const history = useHistory();

  const schema = yup.object().shape({
    email: yup.string().required('Required')
  });

  const { control, handleSubmit, errors: formErrors } = useForm<FormValues>({
    resolver: yupResolver(schema)
  });
  const [
    { isLoading: isLoadingUpdate, error: updateError },
    doPatchConsumer
  ] = usePatchConsumer({
    consumerId: consumerData.id
  });

  useEffect(
    () => {
      getConsumer();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const patchData = {
      email: data.email,
      consumerId: consumerData.id
    };
    const patchRes = await doPatchConsumer(patchData);
    if (patchRes && !(patchRes as FetchError).errorCode) {
      dispatch(updateConsumer(patchData));
      history.goBack();
    }
  };
  const handleRefresh = async () => {
    const options = { refresh: true };
    await getConsumer(options);
  };
  const renderSkeleton = () => (
    <>
      <AccountList>
        <AccountHeader>Email</AccountHeader>
        <IonItem>
          <IonLabel position="stacked">Email Address</IonLabel>
          <IonSkeletonText animated style={{ height: '36px', width: '100%' }} />
        </IonItem>
      </AccountList>
    </>
  );

  return (
    <PageLayout
      showBack
      error={consumerError}
      loading={isLoadingConsumer}
      skeleton={renderSkeleton}
      onRefresh={handleRefresh}
      backRoute={routes.accountInfoBack}
    >
      <IonAlert
        isOpen={!!updateError}
        cssClass="modal-dark-mode"
        header="Error"
        message={updateError ? updateError.message : ''}
        buttons={['OK']}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <AccountList>
          <AccountHeader>Email</AccountHeader>
          <ErrorText color="danger">
            {updateError ? updateError.message : ''}
          </ErrorText>
          <IonItem>
            <IonLabel position="stacked">Email Address</IonLabel>
            <TextFieldInput
              name="email"
              type="email"
              value={consumerData.email}
              control={control}
              inputClass="none"
            />
          </IonItem>
          <ErrorText color="danger">
            {formErrors.email ? formErrors.email.message : ''}
          </ErrorText>
          <WarningText>
            Changing your email address will automatically unsubscribe your
            previous email.
          </WarningText>
        </AccountList>
        <div className="modal-button">
          <SaveButton type="submit" disabled={isLoadingUpdate}>
            {isLoadingUpdate ? (
              <IonSpinner title="button-spinner" name="crescent" />
            ) : (
              'SAVE'
            )}
          </SaveButton>
        </div>
      </form>
    </PageLayout>
  );
};

export default ConsumerEmailPage;
