import React from 'react';
import { SectionHeader } from './layout/SectionHeader';
import { Container } from './layout/styled';
import savingsHistoryEmptyState from '../images/savings-history-empty-state.svg';
import styled from 'styled-components';
import { links, shortDeviceMaxHeight } from '../shared/constants';
import { IonButton, IonIcon, IonText } from '@ionic/react';
import { addCircleOutline } from 'ionicons/icons';

const headerHeight = '56px';
const footerHeight = '4rem';

const ContentWrap = styled.div`
  height: calc(100vh - (${headerHeight} + ${footerHeight}));
  width: 100%;
  position: relative;
`;

const ContentStyle = styled.div`
  width: 100%;
  text-align: center;
  padding: 0 0 2em;
`;

const ImageTitle = styled(IonText)`
  display: block;
  margin-top: 40px;

  @media (max-height: ${shortDeviceMaxHeight}) {
    margin-top: 0;
  }
`;

const ImageText = styled(IonText)`
  line-height: 1.75;

  @media (max-height: ${shortDeviceMaxHeight}) {
    line-height: 1;
  }
`;

const AddBusinessesButton = styled(IonButton)`
  position: absolute;
  bottom: 40px;
  left: 20px;
  width: calc(100% - 40px);
  span {
    margin-left: 5px;
  }
`;

export const SavingsHistoryEmptyState = () => (
  <ContentWrap>
    <SectionHeader>Savings History</SectionHeader>
    <Container>
      <ContentStyle>
        <img src={savingsHistoryEmptyState} alt="Savings History" />
        <ImageTitle color="dark">
          <h5>No savings yet</h5>
        </ImageTitle>
        <ImageText color="medium">
          Visit the home screen to find out where and how you can start saving
          or click below to find new businesses where you can save.
        </ImageText>
      </ContentStyle>
      <AddBusinessesButton
        color="primary"
        href={links.businessMap}
        target="_blank"
      >
        <IonIcon icon={addCircleOutline} />
        <span>Add businesses</span>
      </AddBusinessesButton>
    </Container>
  </ContentWrap>
);
