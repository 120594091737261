import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import styled from 'styled-components';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { person, chevronBackOutline } from 'ionicons/icons';
import logo from '../images/logo.png';
import { routes } from '../shared/constants';

export interface HeaderProps {
  showBack?: boolean;
  backRoute?: string;
}

const Container = styled(IonTitle)`
  text-align: left;
  display: flex;
  flex-direction: row-reverse;
`;

const Logo = styled.img`
  width: 60px;

  &.logo-mobile {
    padding-left: 20px;
  }
  &.logo-web {
    cursor: pointer;
  }

  ::part(image) {
    width: 100px;
  }
`;

const Links = styled.span`
  display: flex;
  font-size: 14px;
  justify-self: flex-end;
  justify-content: space-between;
  a {
    margin-left: 1rem;
    color: #f2f2f2;
    text-decoration: unset;
  }
`;

const AccountIcon = styled(IonIcon)`
  height: 20px;
  width: 20px;
`;

const StyledToolbar = styled(IonToolbar)`
  --background: var(--ion-color-primary);
`;

const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
`;

const ChevronIcon = styled(IonIcon)`
  width: 27px;
  height: 27px;
`;

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Header: React.FC<HeaderProps> = ({
  showBack = false,
  backRoute
}) => {
  const { push } = useHistory();
  const location = useLocation();
  const { appV2 } = useFlags();
  const isWidget = location.pathname.includes('widget');
  const defaultRoute =
    backRoute || (isWidget ? `/widget/${routes.home}` : `/tabs/${routes.home}`);
  const Toolbar = appV2 ? StyledToolbar : IonToolbar;

  const renderBackOrLogo = () => {
    if (showBack) {
      if (!appV2) {
        return (
          <IonButtons
            slot="start"
            className="web-hide"
            data-testid="back-button"
          >
            <IonBackButton defaultHref={defaultRoute} />
          </IonButtons>
        );
      }
      return (
        <BackButtonContainer
          className="web-hide"
          data-testid="back-button"
          onClick={() => push(defaultRoute)}
        >
          <ChevronIcon icon={chevronBackOutline} />
          <p>Back</p>
        </BackButtonContainer>
      );
    }
    return <Logo className="logo-mobile web-hide" src={logo} />;
  };

  return (
    <IonHeader>
      <Toolbar>
        {renderBackOrLogo()}
        {!isWidget ? (
          <IonButtons className={showBack ? 'mobile-hide' : ''} slot="end">
            <IonButton routerLink={routes.accountInfo}>
              <AccountIcon icon={person} />
            </IonButton>
          </IonButtons>
        ) : (
          ''
        )}
        <Container className="mobile-hide">
          <Links>
            {showBack && appV2 ? (
              <BackButtonContainer onClick={() => push(defaultRoute)}>
                <ChevronIcon icon={chevronBackOutline} />
                <p>Back</p>
              </BackButtonContainer>
            ) : (
              <Logo
                src={logo}
                className="logo-web"
                onClick={() => push('/tabs/home')}
              />
            )}
            <MenuContainer>
              <Link to="/tabs/home">HOME</Link>
              {!appV2 && <Link to="/tabs/wallet">WALLET</Link>}
              <Link to="/tabs/rewards">
                {!appV2 ? 'REWARDS' : 'SAVINGS HISTORY'}
              </Link>
              <Link to="/tabs/more">MORE</Link>
            </MenuContainer>
          </Links>
        </Container>
      </Toolbar>
    </IonHeader>
  );
};

export default Header;
