import { ActionCreator } from '../actions/actions-types';

export const purchasesActionTypes = {
  SET_CONSUMER_PURCHASES: 'setConsumerPurchases',
  SET_CONSUMER_PURCHASE_PAY: 'setConsumerPurchasePay',
  ADD_PURCHASE: 'addPurchase'
};

export const setConsumerPurchases: ActionCreator = (payload) => ({
  type: purchasesActionTypes.SET_CONSUMER_PURCHASES,
  payload
});

export const setConsumerPurchasePay: ActionCreator = () => ({
  type: purchasesActionTypes.SET_CONSUMER_PURCHASE_PAY
});

export const addPurchase: ActionCreator = (payload) => ({
  type: purchasesActionTypes.ADD_PURCHASE,
  payload
});
