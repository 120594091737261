import React from 'react';
import {
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSkeletonText,
  IonThumbnail
} from '@ionic/react';
import { informationCircle as info } from 'ionicons/icons';
import styled from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Store } from '../../features/stores/stores-types';

const StoreHeader = styled.h2`
  font-size: 18px !important;
  font-weight: 500 !important;
`;

const StoreSubHeader = styled.h3`
  font-size: 14px;
  color: var(--description-text);
`;

const StoreInfoHeader = styled.h3`
  color: var(--description-text);
  display: flex;
  align-items: center;
  text-decoration: underline;

  p {
    font-size: 12px;
  }

  ion-icon {
    padding-right: 4px;
  }
`;

const Col = styled(IonCol)`
  max-width: 960px !important;
`;

const Row = styled(IonRow)<{
  bg?: string;
}>`
  background: ${({ bg }) => bg || ''};
`;

const StoreItem = styled(IonItem)<{ borderBottom?: boolean }>`
  --inner-border-width: 0px;
  --background-hover-opacity: 0;

  border-bottom: ${({ borderBottom }) =>
    borderBottom ? '1px solid #D2D2D2' : ''};
`;

const StoreThumbnail = styled(IonThumbnail)`
  width: 83px;
  height: 83px;
  --border-radius: 9px;
`;

const V2StoreThumbnail = styled(StoreThumbnail)`
  width: 65px;
  height: 65px;
  margin: 20px 0;
  --border-radius: 50%;
`;

export interface StoreSubheaderProps {
  store: Store;
  loading?: boolean;
  storeInfoPath?: string;
}

export const StoreSubheader: React.FC<StoreSubheaderProps> = ({
  store,
  storeInfoPath,
  loading
}) => {
  const { appV2 } = useFlags();
  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const renderSkeleton = () => (
    <IonGrid className="ion-no-padding">
      <Row
        bg={prefersDark ? '#000' : '#fff'}
        className="ion-justify-content-center ion-color-light"
      >
        <Col sizeLg="7">
          <IonList id="skeleton">
            <StoreItem detail={false}>
              <StoreThumbnail slot="end">
                <IonSkeletonText animated />
              </StoreThumbnail>
              <IonLabel>
                <h3>
                  <IonSkeletonText animated style={{ width: '50%' }} />
                </h3>
                <h3>
                  <IonSkeletonText animated style={{ width: '80%' }} />
                </h3>
                <p>
                  <IonSkeletonText animated style={{ width: '60%' }} />
                </p>
                {storeInfoPath && (
                  <p>
                    <IonSkeletonText animated style={{ width: '30%' }} />
                  </p>
                )}
              </IonLabel>
            </StoreItem>
          </IonList>
        </Col>
      </Row>
    </IonGrid>
  );

  return loading ? (
    renderSkeleton()
  ) : (
    <IonGrid className="ion-no-padding">
      <Row
        bg={appV2 ? '' : 'var(--ion-color-light, #f4f5f8) !important'}
        className="ion-justify-content-center ion-color-light"
      >
        <Col sizeLg="7">
          <StoreItem
            key="store"
            color={appV2 ? '' : 'light'}
            detail={false}
            routerLink={storeInfoPath}
            borderBottom={appV2}
          >
            <IonLabel>
              <StoreHeader>{store?.name}</StoreHeader>
              <StoreSubHeader>{store?.streetAddress}</StoreSubHeader>
              {storeInfoPath && !appV2 && (
                <StoreInfoHeader>
                  <IonIcon icon={info} />
                  <p>More Info</p>
                </StoreInfoHeader>
              )}
            </IonLabel>
            {store?.files?.logo && !appV2 && (
              <StoreThumbnail>
                <img src={store.files.logo} alt="logo" />
              </StoreThumbnail>
            )}
            {store?.files?.logo && appV2 && (
              <V2StoreThumbnail>
                <img src={store.files.logo} alt="logo" />
              </V2StoreThumbnail>
            )}
          </StoreItem>
        </Col>
      </Row>
    </IonGrid>
  );
};
