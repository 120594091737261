import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { IonAlert, IonLoading } from '@ionic/react';
import { isEmpty } from 'lodash';
import { useAuth, useLoginAccessToken } from '../features/auth/auth-resolver';
import { useQuery } from '../utils/hooks';
import { useAppState } from '../shared/AppContext';
import { clearState } from '../features/actions/actions';
import { setAllNotifications } from '../features/notifications/notifications-actions';

const Widget: React.FC = () => {
  const { dispatch } = useAppState();
  const history = useHistory();
  const auth = useAuth();
  const { accessToken = '' } = useQuery();

  const [
    { isLoading: isLoadingLogin, error },
    doLoginAccessToken
  ] = useLoginAccessToken(accessToken as string);

  useEffect(() => {
    if (!isEmpty(auth) && auth.id !== accessToken) {
      // clear state if accessToken doesn't match currently logged in user
      dispatch(clearState());
    } else if (isEmpty(auth)) {
      // when we clear state, we'll want to login the user with new access token
      doLoginAccessToken();
    } else {
      // new logged in user (or already logged in user) should be routed to home
      dispatch(setAllNotifications(false));
      history.push('/widget/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return (
    <>
      <IonLoading isOpen={isLoadingLogin} message="Please wait..." />

      <IonAlert
        isOpen={error}
        cssClass="modal-dark-mode"
        header="Error logging in"
        message="There was an error logging in to your account."
        buttons={['OK']}
      />
    </>
  );
};

export default Widget;
