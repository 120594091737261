import { ActionCreator } from '../actions/actions-types';

export const transactionsActionTypes = {
  SET_TRANSACTIONS: 'transactions/set'
};

export const setTransactions: ActionCreator = (payload) => ({
  type: transactionsActionTypes.SET_TRANSACTIONS,
  payload
});
