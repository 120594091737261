import { PlaidOauthState, PlaidState } from './plaid-types';
import { Action } from '../actions/actions-types';
import { plaidActionTypes } from './plaid-actions';
import { BaseMap } from '../../types/generic';

export const plaidInitialState = (): PlaidState => ({} as PlaidState);
export const plaidOauthInitialState = (): PlaidOauthState =>
  ({} as PlaidOauthState);

export const plaidReducer = (
  state = plaidInitialState(),
  action: Action<BaseMap>
) => {
  const { payload, type } = action;
  switch (type) {
    case plaidActionTypes.RESET_PLAID_TOKEN:
      return { ...state, plaidLinkToken: undefined };
    case plaidActionTypes.SET_PLAID_LOADING:
      return { ...state, loading: payload };
    case plaidActionTypes.SET_PLAID_INFO:
    case plaidActionTypes.SET_PLAID_ACCESS_TOKEN:
    case plaidActionTypes.DELETE_PLAID_TOKEN:
      return {
        ...state,
        loading: false,
        plaidAccessTokens: payload?.accessTokens ?? []
      };
    case plaidActionTypes.SET_PLAID_LINK_TOKEN:
      return { ...state, loading: false, plaidLinkToken: payload };
    default:
      return state;
  }
};

export const plaidOauthReducer = (
  state = plaidOauthInitialState(),
  action: Action<BaseMap>
) => {
  const { payload, type } = action;
  switch (type) {
    case plaidActionTypes.SET_PLAID_OAUTH_TOKEN:
      return { ...state, ...payload };
    case plaidActionTypes.CLEAR_PLAID_OAUTH_TOKEN:
      return plaidOauthInitialState();
    default:
      return state;
  }
};
