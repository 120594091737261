import { ActionCreator } from '../features/actions/actions-types';

// TODO: create feature?
export const TYPES = {
  SET_CARD_TOKEN: 'setCardToken'
};

export const setCardToken: ActionCreator = (payload) => ({
  type: TYPES.SET_CARD_TOKEN,
  payload
});
