import { actionsReducer } from '../features/actions/actions-reducer';
import { authReducer } from '../features/auth/auth-reducer';
import { campaignsReducer } from '../features/campaigns/campaigns-reducer';
import { consumerReducer } from '../features/consumer/consumer-reducer';
import { notificationsReducer } from '../features/notifications/notifications-reducer';
import { purchasesReducer } from '../features/purchases/purchases-reducer';
import { storeCampaignsReducer } from '../features/store-campaigns/store-campaigns-reducer';
import { storesReducer } from '../features/stores/stores-reducer';
import { walletReducer } from '../features/wallet/wallet-reducer';
import { transactionsReducer } from '../features/transactions/transactions-reducer';
import { consumerStoresReducer } from '../features/consumer-stores/consumer-stores-reducer';
import { queryReducer } from '../features/query/query-reducer';

import { BaseMap } from '../types/generic';
import { AppState, initialState } from './state';
import { Action } from '../features/actions/actions-types';
import {
  plaidReducer,
  plaidOauthReducer
} from '../features/plaid/plaid-reducer';

export function combineReducers(reducers: BaseMap<Reducer>) {
  return (state: AppState = initialState, action: Action<never>) => {
    const newState: Partial<AppState> = {};
    for (const key in reducers) {
      // guarding against unnecessary prototype field iterations
      // see: https://eslint.org/docs/rules/guard-for-in
      if (Object.prototype.hasOwnProperty.call(reducers, key)) {
        newState[key as keyof AppState] = reducers[key](
          state[key as keyof AppState],
          action
        );
      }
    }
    return newState as AppState;
  };
}

export const reducer = combineReducers({
  actions: actionsReducer,
  auth: authReducer,
  campaigns: campaignsReducer,
  consumer: consumerReducer,
  consumerStores: consumerStoresReducer,
  notifications: notificationsReducer,
  purchases: purchasesReducer,
  storeCampaigns: storeCampaignsReducer,
  stores: storesReducer,
  wallet: walletReducer,
  transactions: transactionsReducer,
  query: queryReducer,
  plaid: plaidReducer,
  plaidOauth: plaidOauthReducer
});

export type Reducer<T = any> = (state: T, action: Action<never>) => T;
