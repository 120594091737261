import { ActionCreator } from '../actions/actions-types';

export const storeCampaignsActionTypes = {
  SET_STORE_CAMPAIGNS: 'setStoreCampaigns'
};

export const setStoreCampaigns: ActionCreator = (payload) => ({
  type: storeCampaignsActionTypes.SET_STORE_CAMPAIGNS,
  payload
});
