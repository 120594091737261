import React from 'react';
import styled from 'styled-components';
import { formatDatetime } from '../../../utils/format';
import { Transaction } from '../../../features/transactions/transactions-types';

export interface TransactionItemDetailsProps {
  transaction: Transaction;
}

const Date = styled.div`
  font-size: 0.8rem;
  color: var(--description-text);
  margin-bottom: 0.2rem;
`;

const Amount = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 0.2rem;
`;

const StoreName = styled.div`
  font-size: 1.1rem;
  color: var(--description-text);
`;

const TransactionDetailsPanel = styled.div`
  flex-grow: 1;
`;

export const TransactionItemDetails: React.FC<TransactionItemDetailsProps> = ({
  transaction
}) => {
  const {
    data: { amount },
    timestamp,
    storeName
  } = transaction;
  return (
    <TransactionDetailsPanel data-testid="transaction-item-details">
      <Date data-testid="date">{formatDatetime(timestamp)}</Date>
      <Amount data-testid="amount">${Number(amount).toFixed(2)}</Amount>
      <StoreName data-testid="store">{storeName}</StoreName>
    </TransactionDetailsPanel>
  );
};
