import { useIonRouter } from '@ionic/react';
import { Plugins } from '@capacitor/core';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useURLQuery } from '../features/query/query-resolver';

const Root: React.FC = () => {
  const { storeURLQuery } = useURLQuery();

  const ionRouter = useIonRouter();
  document.addEventListener('ionBackButton', (ev) => {
    (ev as CustomEvent).detail.register(-1, () => {
      if (!ionRouter.canGoBack()) {
        Plugins.App.exitApp();
      }
    });
  });

  useEffect(
    () => {
      // storing URL query params if exists.
      storeURLQuery();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <Redirect exact path="/" to="/tabs" />;
};

export default Root;
