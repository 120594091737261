import React from 'react';
import Loadable from 'react-loadable';
import { useReadyFlags } from '../../utils/hooks';

type CampaignPageProps = {
  storeId: number;
  showBack: boolean;
};

const CampaignPageV1 = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "campaign-v1" */
      /* webpackMode: "lazy" */
      './CampaignPageV1'
    ),
  loading: () => null
});
const CampaignPageV2 = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "campaign-v2" */
      /* webpackMode: "lazy" */
      './CampaignPageV2'
    ),
  loading: () => null
});

export const CampaignPage: React.FC<CampaignPageProps> = () => {
  const flags = useReadyFlags();
  const { loading, appV2 } = flags;
  if (loading) return null;
  if (appV2) return <CampaignPageV2 />;
  return <CampaignPageV1 />;
};
