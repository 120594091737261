import React from 'react';

type MapProps = { latitude: number; longitude: number; markerColor: string };

const googleApiKey = 'AIzaSyAg3A9rZZwA-mNKwmppXGGBzvAL3xRJ5jE';
const mapUrl = 'https://maps.googleapis.com/maps/api/staticmap';

export const Map: React.FC<{
  latitude: number;
  longitude: number;
  markerColor: string;
}> = ({ latitude, longitude, markerColor }: MapProps) => {
  return (
    <img
      src={`${mapUrl}?center=${latitude},${longitude}&zoom=13&size=600x300&maptype=roadmap
&markers=color:0x${markerColor.replace(
        '#',
        ''
      )}%7Clabel%7C${latitude},${longitude}&style=
&key=${googleApiKey}`}
      alt="map"
    />
  );
};

export default Map;
