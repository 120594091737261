import { useGet } from '../fetch/fetch-resolver';
import { setTransactions } from './transactions-actions';
import { useAuth } from '../auth/auth-resolver';
import { Transaction } from './transactions-types';
import { PaginatedResponse } from '../../types/api';
import { UseGetOptions } from '../fetch/fetch-types';

export const useGetTransactions = (options = {} as Partial<UseGetOptions>) => {
  const auth = useAuth();

  return useGet<PaginatedResponse<Transaction>>({
    url: `consumers/:consumerId/transactions`,
    version: 'v2',
    action: setTransactions,
    selector: (state) => state.transactions,
    initialParams: {
      page: 1,
      limit: 50,
      consumerId: auth.consumerId || auth.userId
    },
    ...options
  });
};
