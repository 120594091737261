import { BaseMap } from '../../types/generic';
import { Campaign } from '../campaigns/campaigns-types';

export interface BaseTransaction {
  readonly id: number;
  readonly data: BaseTransactionData;
  readonly sourceId: string;
  readonly source: string;
  readonly storeId: number;
  readonly storeName: string;
  readonly timestamp: string;
  readonly consumerId: number;
  readonly custom?: BaseMap;
  readonly createdAt?: string;
  readonly updatedAt?: string;
  readonly campaigns?: Campaign[];
}

export interface BaseTransactionData {
  readonly amount: number;
}

export interface SettlementTransaction extends BaseTransaction {
  readonly type: TransactionType.SETTLEMENT;
}

export interface AuthTransaction extends BaseTransaction {
  readonly type: TransactionType.AUTHORIZATION;
}

export type Transaction = SettlementTransaction | AuthTransaction;

export enum TransactionType {
  AUTHORIZATION = 'Authorization',
  SETTLEMENT = 'Settlement'
}
