import { IonList, IonItem, IonIcon } from '@ionic/react';
import React from 'react';
import {
  call,
  location,
  logoInstagram,
  logoFacebook,
  logoTwitter
} from 'ionicons/icons';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Store } from '../features/stores/stores-types';

const googleMapUrl = 'http://maps.google.com/?q=';

type StoreInfoPageProps = {
  store: Store;
};

const StoreList = styled(IonList)`
  padding-top: 0px;
  padding-bottom: 0px;
`;

const StoreInfoItem = styled(IonItem)`
  font-size: 13px;
  --background-hover: none;
`;

const StoreSocialItem = styled(IonItem)`
  padding-top: 12px;
  --inner-border-width: 0px;
`;

const StoreDescItem = styled(IonItem)`
  font-size: 16px;
  line-height: 21px;
  --inner-border-width: 0px;
  margin: 20px auto;
  color: #666666;
`;

const SocialButton = styled.a`
  color: white;
  border-radius: 19px;
  height: 38px;
  width: 38px;
  font-size: 20px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AboutText = styled.p`
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  margin-top: 20px;
  margin-left: 20px;
`;

const StoreImage = styled.img`
  display: block;
  margin: 20px auto;
  width: 95%;
`;

const StoreInfoPage: React.FC<StoreInfoPageProps> = ({ store }) => {
  const fullAddress = `${store.streetAddress}, ${store.city} ${store.state} ${store.zipCode}`;

  return (
    <>
      <AboutText>{`About ${store.name}`}</AboutText>
      {!isEmpty(store) && (
        <StoreList>
          {store.isStorefront && (
            <StoreInfoItem
              href={`${googleMapUrl}${fullAddress}`}
              target="_blank"
              detail={false}
            >
              <IonIcon
                className="store-info-icon"
                icon={location}
                slot="start"
              />
              {store.streetAddress}, {store.city} {store.state}
            </StoreInfoItem>
          )}
          {store.phone && (
            <StoreInfoItem href={`tel:${store.phone}`} detail={false}>
              <IonIcon className="store-info-icon" icon={call} slot="start" />
              {store.phone}
            </StoreInfoItem>
          )}
          {(store.facebookUrl || store.twitterUrl || store.instagramUrl) && (
            <StoreSocialItem>
              {store.instagramUrl && (
                <SocialButton href={store.instagramUrl} target="_blank">
                  <IonIcon className="store-info-icon" icon={logoInstagram} />
                </SocialButton>
              )}

              {store.facebookUrl && (
                <SocialButton href={store.facebookUrl} target="_blank">
                  <IonIcon className="store-info-icon" icon={logoFacebook} />
                </SocialButton>
              )}

              {store.twitterUrl && (
                <SocialButton href={store.twitterUrl} target="_blank">
                  <IonIcon className="store-info-icon" icon={logoTwitter} />
                </SocialButton>
              )}
            </StoreSocialItem>
          )}

          {store.files.secondary && (
            <StoreImage src={store.files.secondary} alt="banner" />
          )}

          {store.description && (
            <StoreDescItem>{store.description}</StoreDescItem>
          )}
        </StoreList>
      )}
    </>
  );
};

export default StoreInfoPage;
