import {
  IonList,
  IonItem,
  IonListHeader,
  IonLabel,
  IonRouterLink
} from '@ionic/react';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { PageLayout } from '../components/layout/PageLayout';
import {
  useConsumerData,
  useGetConsumer
} from '../features/consumer/consumer-resolver';
import { routes } from '../shared/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';

const AccountHeader = styled(IonListHeader)`
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: bold;
  font-size: 18pt;
`;

const AccountList = styled(IonList)`
  padding-right: 20px;
`;

const StyledIonLabel = styled(IonLabel)`
  font-weight: bold;
`;

const SignOutStyle = styled.div`
  text-align: center;
  padding: 20px 0;
`;

const SignOutStyleLink = styled(IonRouterLink)`
  font-weight: bold;
  text-decoration: underline;
`;

const AccountInfoPage: React.FC = () => {
  const [{ error: consumerError }, getConsumer] = useGetConsumer();
  const consumerData = useConsumerData();
  const { enablePlaid } = useFlags();

  useEffect(
    () => {
      getConsumer();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const footerElement = (
    <SignOutStyle>
      <SignOutStyleLink color="dark" href="logout">
        Sign out
      </SignOutStyleLink>
    </SignOutStyle>
  );

  return (
    <PageLayout error={consumerError} showBack footerElement={footerElement}>
      <AccountList>
        <AccountHeader>Account</AccountHeader>
        <IonItem routerLink={routes.consumerName} detail={false}>
          <StyledIonLabel>Name</StyledIonLabel>
          {consumerData.firstName} {consumerData.lastName}
        </IonItem>
        <IonItem routerLink={routes.consumerEmail} detail={false}>
          <StyledIonLabel>Email</StyledIonLabel>
          {consumerData.email}
        </IonItem>
        <IonItem routerLink={routes.consumerPassword} detail={false}>
          <StyledIonLabel>Password</StyledIonLabel>
          ********
        </IonItem>
        <IonItem routerLink={routes.consumerBirthday} detail={false}>
          <StyledIonLabel>Birthday</StyledIonLabel>
          {consumerData.birthday
            ? moment(consumerData.birthday).format('MMM D')
            : 'Select Date'}
        </IonItem>
        {Boolean(enablePlaid) && (
          <IonItem routerLink={routes.connectAccount} detail={false}>
            <StyledIonLabel>Your Linked Accounts</StyledIonLabel>
          </IonItem>
        )}
      </AccountList>
    </PageLayout>
  );
};

export default AccountInfoPage;
