import {
  IonLabel,
  IonButton,
  IonSpinner,
  IonText,
  IonItem,
  IonList,
  IonListHeader,
  IonAlert
} from '@ionic/react';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { PageLayout } from '../components/layout/PageLayout';

import {
  useConsumerData,
  useGetConsumer,
  usePatchConsumer
} from '../features/consumer/consumer-resolver';
import { FetchError } from '../features/fetch/fetch-types';
import TextFieldInput from '../components/form/TextFieldInput';
import { updateConsumer } from '../features/consumer/consumer-actions';
import { useAppState } from '../shared/AppContext';
import { routes } from '../shared/constants';
import {
  passwordValidation,
  regexPasswordMatch
} from '../utils/passwordValidation';

type FormValues = {
  currentPassword: 'string';
  newPassword: 'string';
  confirmNewPassword: 'string';
};

const SaveButton = styled(IonButton)`
  margin-left: 20px;
  margin-right: 20px;
`;

const ErrorText = styled(IonText)`
  padding-top: 20px;
  padding-left: 20px;
  display: block;
`;

const AccountHeader = styled(IonListHeader)`
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: bold;
  font-size: 18pt;
`;

const AccountList = styled(IonList)`
  padding-right: 20px;
`;

const StyledTextInput = styled(TextFieldInput)`
  background: none;
`;

const ConsumerPasswordPage: React.FC = () => {
  const [{ error: consumerError }, getConsumer] = useGetConsumer();
  const consumerData = useConsumerData();
  const { dispatch } = useAppState();
  const history = useHistory();
  const [
    { isLoading: isLoadingUpdate, error: updateError },
    doPatchConsumer
  ] = usePatchConsumer({
    consumerId: consumerData.id
  });

  useEffect(() => {
    getConsumer();
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  const schema = yup.object().shape({
    currentPassword: yup.string().required('Required'),
    newPassword: yup.string().required('Required'),
    confirmNewPassword: yup
      .string()
      .required('Required')
      .test('confirmNewPassword', passwordValidation, regexPasswordMatch)
      .oneOf([yup.ref('newPassword')], 'Passwords do not match')
  });

  const { control, handleSubmit, errors: formErrors } = useForm<FormValues>({
    resolver: yupResolver(schema)
  });

  const onSubmit: SubmitHandler<FormValues> = async data => {
    const patchData = {
      password: data.confirmNewPassword,
      currentPassword: data.currentPassword,
      consumerId: consumerData.id
    };
    const patchRes = await doPatchConsumer(patchData);
    if (patchRes && !(patchRes as FetchError).errorCode) {
      dispatch(updateConsumer(patchData));
      history.goBack();
    }
  };

  return (
    <PageLayout
      error={consumerError}
      showBack
      backRoute={routes.accountInfoBack}
    >
      <IonAlert
        isOpen={!!updateError}
        cssClass="modal-dark-mode"
        header="Error"
        message={updateError ? updateError.message : ''}
        buttons={['OK']}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <AccountList>
          <AccountHeader>Password</AccountHeader>
          <IonItem>
            <IonLabel position="stacked">Current Password</IonLabel>
            <StyledTextInput
              name="currentPassword"
              control={control}
              type="password"
              inputClass="none"
            />
          </IonItem>
          <ErrorText color="danger">
            {formErrors.currentPassword
              ? formErrors.currentPassword.message
              : ''}
          </ErrorText>

          <IonItem>
            <IonLabel position="stacked">New Password</IonLabel>
            <TextFieldInput
              name="newPassword"
              control={control}
              type="password"
              inputClass="none"
            />
          </IonItem>
          <ErrorText color="danger">
            {formErrors.newPassword ? formErrors.newPassword.message : ''}
          </ErrorText>

          <IonItem>
            <IonLabel position="stacked">Re-enter Password</IonLabel>
            <TextFieldInput
              name="confirmNewPassword"
              control={control}
              type="password"
              inputClass="none"
            />
          </IonItem>
          <ErrorText color="danger">
            {formErrors.confirmNewPassword
              ? formErrors.confirmNewPassword.message
              : ''}
          </ErrorText>
        </AccountList>
        <div className="modal-button">
          <SaveButton type="submit" disabled={isLoadingUpdate}>
            {isLoadingUpdate ? (
              <IonSpinner title="button-spinner" name="crescent" />
            ) : (
              'SAVE'
            )}
          </SaveButton>
        </div>
      </form>
    </PageLayout>
  );
};

export default ConsumerPasswordPage;
