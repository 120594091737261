import React from 'react';
import Loadable from 'react-loadable';
import { useReadyFlags } from '../../utils/hooks';

type StorePageProps = {
  storeId: number;
  showBack: boolean;
};

const StorePageV1 = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "store-page-v1" */
      /* webpackMode: "lazy" */
      './StorePageV1'
    ),
  loading: () => null
});
const StorePageV2 = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "store-page-v2" */
      /* webpackMode: "lazy" */
      './StorePageV2'
    ),
  loading: () => null
});

export const StorePage: React.FC<StorePageProps> = ({ storeId, showBack }) => {
  const flags = useReadyFlags();
  const { loading, appV2 } = flags;
  if (loading) return null;
  if (appV2) return <StorePageV2 storeId={storeId} showBack={showBack} />;
  return <StorePageV1 storeId={storeId} showBack={showBack} />;
};
