import { BaseMap } from '../../types/generic';
import { Consumer } from './consumer-types';
import { actionTypes } from '../actions/actions';
import { Action } from '../actions/actions-types';
import { consumerActionTypes } from './consumer-actions';

export const consumerInitialState = () => ({} as Consumer);

export const consumerReducer = (
  state = consumerInitialState(),
  action: Action<BaseMap>
): any => {
  switch (action.type) {
    case consumerActionTypes.SET_CONSUMER:
      return action.payload;

    case consumerActionTypes.SET_CONSUMER_CARD:
      return {
        ...state,
        cardData: [{ ...action.payload }, ...state.cardData]
      };

    case consumerActionTypes.UPDATE_CONSUMER:
      return {
        ...state,
        ...action.payload
      };

    case actionTypes.CLEAR_STATE:
      return consumerInitialState();

    default:
      return state;
  }
};
