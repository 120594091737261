import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton
} from '@ionic/react';
import React from 'react';
import { links } from '../shared/constants';

interface Props {
  backRoute: string;
}

const PrivacyPolicyPage: React.FC<Props> = ({ backRoute }) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={backRoute} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <iframe
          title="Privacy Policy"
          src={links.privacy}
          style={{ height: '100%', width: '100%' }}
        />
      </IonContent>
    </IonPage>
  );
};

export default PrivacyPolicyPage;
