import React from 'react';
import { IonButton } from '@ionic/react';
import styled from 'styled-components';
import { CenteredContainer } from './layout/styled';
import { links, shortDeviceMaxHeight } from '../shared/constants';

const EmptyStateHeader = styled.h1`
  font-size: 22px;
  font-weight: 800;

  @media (max-height: ${shortDeviceMaxHeight}) {
    margin: 10px 0;
  }
`;

const EmptyStateText = styled.p`
  font-size: 17px;
  line-height: 34px;
  margin-bottom: 1em;
  font-weight: 300;

  @media (max-height: ${shortDeviceMaxHeight}) {
    line-height: 1;
    margin: 0;
  }
`;

const EmptyStateImg = styled.img`
  ::part(image) {
    width: 280px;
    display: inline;
    margin-bottom: 40px;
  }

  @media (max-width: 360px) {
    width: 80% !important;
  }

  @media (max-height: ${shortDeviceMaxHeight}) {
    max-height: 30%;
  }
`;

interface Props {
  image: string;
}

export const NoStoresEmptyState: React.FC<Props> = ({ image }) => {
  return (
    <CenteredContainer>
      <EmptyStateImg src={image} />
      <EmptyStateHeader>Add connections to save!</EmptyStateHeader>
      <EmptyStateText>
        Find and connect with businesses in your neighborhood to start saving!
      </EmptyStateText>
      <IonButton expand="block" href={links.businessMap} target="_blank">
        FIND A BUSINESS
      </IonButton>
    </CenteredContainer>
  );
};

export default NoStoresEmptyState;
