import React from 'react';
import { IonItem, IonIcon, IonButton } from '@ionic/react';
import styled from 'styled-components';
import { addCircleOutline } from 'ionicons/icons';
import { links } from '../shared/constants';

const AddLink = styled.a`
  width: 100%;
  padding-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ion-color-light-contrast);
  font-weight: 500;

  ion-icon {
    font-size: 28px;
    padding-right: 10px;
  }
`;

const StyledItem = styled(IonItem)`
  padding-bottom: 1rem;
`;

const StyledButton = styled(IonButton)`
  text-transform: none;
  font-weight: 500;

  // desktop
  @media (min-width: 760px) {
    width: 350px;
  }
`;

const AddIcon = styled(IonIcon)`
  margin-right: 5px;
`;

export const AddMoreBusinesses: React.FC<{ button?: boolean }> = ({
  button
}) => {
  return button ? (
    <StyledButton href={links.businessMap} target="_blank">
      <AddIcon icon={addCircleOutline} />
      Add businesses
    </StyledButton>
  ) : (
    <StyledItem key="add" target="_blank" lines="none">
      <AddLink href={links.businessMap} target="_blank">
        <IonIcon icon={addCircleOutline} />
        <p>Add more businesses</p>
      </AddLink>
    </StyledItem>
  );
};

export default AddMoreBusinesses;
