import { LDProvider } from 'launchdarkly-react-client-sdk';
import React, { useEffect } from 'react';
import { IonApp } from '@ionic/react';
import { Plugins } from '@capacitor/core';
import Router from './shared/Router';
import { AppContextConsumer, AppContextProvider } from './shared/AppContext';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/app.css';

const { Keyboard } = Plugins;

const App: React.FC = () => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    Keyboard.setAccessoryBarVisible({ isVisible: true }).catch(() => {});
  }, []);

  return (
    <AppContextProvider>
      <AppContextConsumer>
        {({ state }) => (
          <LDProvider
            clientSideID={`${process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID}`}
            user={{
              key: String(state.auth.id),
              secondary: String(state.auth.consumerId)
            }}
            deferInitialization={false}
          >
            <IonApp>
              <Router />
            </IonApp>
          </LDProvider>
        )}
      </AppContextConsumer>
    </AppContextProvider>
  );
};

export default App;
