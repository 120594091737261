import { useGet, usePost } from '../fetch/fetch-resolver';
import { useAuth } from '../auth/auth-resolver';
import { StoreSummary, ConsumerStoreRequest } from './consumer-stores-types';
import { setConsumerStores } from './consumer-stores-actions';

export const useGetConsumerStores = () => {
  const auth = useAuth();

  return useGet<StoreSummary[]>({
    url: 'consumers/:consumerId/stores',
    version: 'v2',
    initialParams: { consumerId: auth.consumerId },
    action: setConsumerStores,
    selector: (state) => state.consumerStores
  });
};

export const usePostConsumerStore = (request?: ConsumerStoreRequest) => {
  const url = 'consumers/:consumerId/stores';

  return usePost<ConsumerStoreRequest>({
    url,
    version: 'v2',
    initialData: request
  });
};
