import React, { useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonModal,
  IonToolbar
} from '@ionic/react';
import styled from 'styled-components';
import { closeOutline } from 'ionicons/icons';
import iconCreditCard from '../../images/icon-credit-card.svg';
import iconPurchase from '../../images/icon-purchase.svg';
import iconReward from '../../images/icon-reward.svg';
import { PlaidLinking } from '../../pages/link-account/components/PlaidLinking';

interface Props {
  closeHandler: () => any;
  open?: boolean;
  backRoute?:
    | 'storesHome'
    | 'storeCampaignsHome'
    | 'walletCampaignsHome'
    | 'home';
}

const CenteredContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 14px;
  height: calc(100% - 44px);
  max-width: 400px;
  margin: 0 auto;

  .center-wrap {
    width: 100%;
    text-align: center;
    padding: 14px;
    display: flex;
    align-items: center;

    .description {
      flex-grow: 1;
      margin-left: 14px;
      text-align: left;
      line-height: 2;
    }

    h2 {
      margin: 0 0 5px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding: 0 0 10px 0;
    }

    p {
      margin: 0;
    }
  }
`;

export const LinkAccountModal: React.FC<Props> = ({
  closeHandler,
  open = false
}) => {
  const [plaidOpen, setPlaidOpen] = useState(false);
  return (
    <IonModal
      isOpen={open}
      showBackdrop={false}
      backdropDismiss={true}
      onDidDismiss={() => {
        // TODO: Remove this raw javascript when the ion-modal backdrop is auto-removed
        // The current version of @ionic/react 5.x has a bug that doesn't remove the modal.
        document.querySelectorAll('ion-modal').forEach((e) => e.remove());
      }}
      cssClass="modal-dark-mode"
    >
      {plaidOpen ? (
        <PlaidLinking
          data-testid="plaid-linking"
          open={plaidOpen}
          onClose={() => setPlaidOpen(false)}
          onSuccess={closeHandler}
        />
      ) : (
        <>
          <IonHeader translucent>
            <IonToolbar className="modal-toolbar">
              <IonButtons slot="primary">
                <IonButton fill="clear" color="dark" onClick={closeHandler}>
                  <IonIcon icon={closeOutline} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <CenteredContent>
            <div className="center-wrap">
              <div className="image">
                <img src={iconCreditCard} alt="Step 1 Card Link" />
              </div>
              <div className="description">
                <h2>Step 1</h2>
                <p>Link your account</p>
              </div>
            </div>

            <div className="center-wrap">
              <div className="image">
                <img src={iconPurchase} alt="Step 2 Card Link" />
              </div>
              <div className="description">
                <h2>Step 2</h2>
                <p>Use it to buy from local businesses</p>
              </div>
            </div>

            <div className="center-wrap">
              <div className="image">
                <img src={iconReward} alt="Step 3 Card Link" />
              </div>
              <div className="description">
                <h2>Step 3</h2>
                <p>Get rewarded!</p>
              </div>
            </div>

            <IonButton onClick={() => setPlaidOpen(true)}>
              CONNECT ACCOUNT
            </IonButton>
          </CenteredContent>
        </>
      )}
    </IonModal>
  );
};
