import React, { useState } from 'react';
import { IonActionSheet, IonAlert, IonButton, IonIcon } from '@ionic/react';
import { ellipsisHorizontal } from 'ionicons/icons';
import styled from 'styled-components';
import { useDeletePlaidToken } from '../../../features/plaid/plaid-resolver';
import { setPlaidLoading } from '../../../features/plaid/plaid-actions';
import { useAppState } from '../../../shared/AppContext';
import { PlaidLinking } from './PlaidLinking';
import { PlaidToken } from '../../../features/plaid/plaid-types';

interface Props {
  token: PlaidToken;
}

const PlaidButton = styled.div`
  margin-right: -24px;
`;

export const PlaidItemMenu = ({ token }: Props) => {
  const { dispatch } = useAppState();
  const [removeAlert, setRemoveAlert] = useState(false);
  const [openRelink, setOpenRelink] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [{ error, isLoading }, deletePlaidToken] = useDeletePlaidToken();
  const { id: replaceTokenId } = token;

  return !replaceTokenId || error || isLoading ? null : (
    <>
      <PlaidButton>
        <IonButton
          onClick={() => setOpenMenu(true)}
          fill="clear"
          size="large"
          color="medium"
          expand="block"
        >
          <IonIcon slot="icon-only" icon={ellipsisHorizontal} />
        </IonButton>
      </PlaidButton>

      <PlaidLinking
        open={openRelink}
        onClose={() => setOpenRelink(false)}
        token={token}
      />

      <IonActionSheet
        isOpen={openMenu}
        onDidDismiss={() => setOpenMenu(false)}
        buttons={[
          {
            text: 'Relink',
            handler: () => setOpenRelink(true)
          },
          {
            text: 'Remove',
            handler: () => setRemoveAlert(true)
          }
        ]}
      />

      <IonAlert
        isOpen={removeAlert}
        header="Remove Accounts?"
        message="This action will remove access to account data and stop any additional savings."
        onDidDismiss={() => setOpenMenu(false)}
        buttons={[
          {
            text: 'Remove',
            handler: () => {
              dispatch(setPlaidLoading(true));
              deletePlaidToken({ tokenId: replaceTokenId });
            }
          },
          {
            text: 'Cancel',
            handler: () => setRemoveAlert(false)
          }
        ]}
      />
    </>
  );
};
