import React, { useState, useRef, useEffect } from 'react';
import valid from 'card-validator';
import {
  IonButton,
  IonCheckbox,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonAlert,
  IonContent,
  IonSpinner
} from '@ionic/react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { lockClosed } from 'ionicons/icons';
import styled from 'styled-components';
import * as yup from 'yup';
import amex from '../../images/amex.svg';
import visa from '../../images/visa.svg';
import mastercard from '../../images/mastercard.svg';
import TextFieldInput from '../form/TextFieldInput';
import {
  usePostCardToken,
  usePostConsumerCard
} from '../../features/consumer/consumer-resolver';
import WaiverText from './WaiverText';
import { parseAPIError } from '../../shared/error-parser';
import { FetchError } from '../../features/fetch/fetch-types';
import { useURLQuery } from '../../features/query/query-resolver';

type FormValues = {
  firstName: 'string';
  lastName: 'string';
  cardNumber: 'string';
  expiration: 'string';
  cvv: 'string';
  zip: 'string';
  acceptTerms: 'boolean';
};

const LinkCardHeader = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-bottom: 2em;
`;

const ModalTitle = styled.h1`
  font-size: 22px;
  font-weight: bold;
`;

const SecureText = styled.div`
  display: flex;
  align-items: center;
  color: var(--description-text);
`;

const StyledIonIcon = styled(IonIcon)`
  margin-right: 5px;
`;

const CardIcons = styled.div`
  display: flex;
`;

const VisaIcon = styled.img`
  margin: 0 1em;
`;

const CardText = styled.p`
  text-align: left;
  color: #7b7b7b;
  font-size: 12px;
  margin-top: 5px;
  padding-left: 20px;
`;

const AcceptTermsWrapper = styled(IonList)`
  padding-left: 20px;
  padding-right: 20px;
  color: var(--description-text);
`;

const StyledCheckbox = styled(IonCheckbox)`
  margin-top: 1em;
  width: 18px;
  height: 18px;
  --background: #c4c4c4;
  --border-color: #666666;
  --border-radius: 3px;
`;

const ErrorText = styled(IonText)`
  padding-left: 20px;
`;

const LinkCardForm = ({
  onButtonClick
}: {
  onButtonClick: () => void;
}): any => {
  const content = useRef<HTMLIonContentElement | null>(null);
  const getCardInfo = (cardNumber: string) => {
    const { card } = valid.number(cardNumber);
    if (!card)
      return {
        niceType: '',
        type: ''
      };

    return {
      niceType: card.niceType,
      type: card.type
    };
  };

  const schema = yup.object().shape({
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    cardNumber: yup
      .string()
      .required('Required')
      .test(
        'card-number',
        'Credit Card number is invalid',
        (value: string | null | undefined) => valid.number(value).isValid
      )
      .test(
        'accepted-card',
        'Only Visa, Mastercard, and American Express cards are accepted',
        (value: string | null | undefined) => {
          const { niceType } = getCardInfo(value as string);
          return ['Visa', 'Mastercard', 'American Express'].includes(niceType);
        }
      ),
    expiration: yup
      .string()
      .required('Required')
      .test(
        'valid-date',
        'Invalid expiration date',
        (value: string | null | undefined) =>
          Boolean(
            value &&
              value.replace(/\D+/g, '').length === 4 &&
              valid.expirationDate(value).isValid
          )
      ),
    cvv: yup
      .string()
      .required('Required')
      .min(3, 'Invalid CVV')
      .max(4, 'Invalid CVV'),
    zip: yup
      .string()
      .required('Required')
      .test(
        'valid-zip',
        'Invalid Zip Code',
        (value: string | null | undefined) => valid.postalCode(value).isValid
      ),
    acceptTerms: yup
      .boolean()
      .required('You must accept the terms and conditions.')
  });

  let errorMessage =
    'There was an error processing your card information. Please contact customer support.';

  const [cardType, setCardType] = useState('visa');
  const [fetching, setFetching] = useState(false);
  const { readURLQueryFromAppState } = useURLQuery();
  const {
    control,
    handleSubmit,
    errors: formErrors
  } = useForm<FormValues>({
    resolver: yupResolver(schema)
  });

  const [{ error: cardTokenError }, doCreateCardToken] = usePostCardToken();

  const [{ error: postConsumerCardError }, doCreateConsumerCard] =
    usePostConsumerCard();

  const normalizeCardDetails = (values: FormValues) => {
    const cardNumber = values.cardNumber.replace(/\D+/g, '');
    const expiration = values.expiration.replace(/\D+/g, '');
    const zip = values.zip.replace(/\D+/g, '');
    // trim white spaces
    const firstName = values.firstName.trim();
    const lastName = values.lastName.trim();
    return { ...values, firstName, lastName, cardNumber, zip, expiration };
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const { storeId, campaignId, businessId } = readURLQueryFromAppState();
    if (fetching) return;

    setFetching(true);
    const normalizedFormData = normalizeCardDetails(data);
    const cardData = {
      lastFour: normalizedFormData.cardNumber.slice(-4),
      firstSix: normalizedFormData.cardNumber.slice(0, 6),
      zipCode: normalizedFormData.zip,
      cvv: normalizedFormData.cvv,
      expiration: normalizedFormData.expiration,
      firstName: normalizedFormData.firstName,
      lastName: normalizedFormData.lastName,
      acceptTerms: normalizedFormData.acceptTerms,
      storeId: storeId ? Number(storeId) : undefined,
      campaignId: campaignId ? Number(campaignId) : undefined,
      businessId: businessId ? Number(businessId) : undefined
    };

    const { token } = await doCreateCardToken({
      number: normalizedFormData.cardNumber
    });
    const res = await doCreateConsumerCard({ ...cardData, token });

    // don't close this page of the modal if there is an error
    if (res && !(res as FetchError).errorCode) {
      onButtonClick();
    } else {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (Object.keys(formErrors).length) {
      content.current &&
        content.current.scrollToTop &&
        content.current.scrollToTop(200);
    }
  }, [formErrors]);
  if (postConsumerCardError) {
    errorMessage = parseAPIError(postConsumerCardError);
  }

  return (
    <IonContent ref={content} className="modal-dark-mode" fullscreen>
      <LinkCardHeader>
        <ModalTitle>Link an eligible payment card</ModalTitle>
        <SecureText>
          <StyledIonIcon icon={lockClosed} />
          <p>Your payment will be processed securely.</p>
        </SecureText>
        <CardIcons>
          <img src={amex} alt="American Express Icon" />
          <VisaIcon src={visa} alt="Visa Icon" />
          <img src={mastercard} alt="Mastercard Icon" />
        </CardIcons>
      </LinkCardHeader>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ErrorText color="danger">
          {formErrors.acceptTerms && formErrors.acceptTerms.message}
        </ErrorText>

        <IonList lines="full" className="modal-dark-mode">
          <IonItem>
            <IonLabel position="stacked">
              First Name <IonText color="danger">*</IonText>
            </IonLabel>
            <TextFieldInput
              autocapitalize="on"
              name="firstName"
              type="text"
              control={control}
              inputClass="link-card-input"
              placeholder="John"
            />
          </IonItem>
          <ErrorText color="danger">
            {formErrors.firstName && formErrors.firstName.message}
          </ErrorText>

          <IonItem>
            <IonLabel position="stacked">
              Last Name <IonText color="danger">*</IonText>
            </IonLabel>
            <TextFieldInput
              autocapitalize="on"
              name="lastName"
              type="text"
              control={control}
              inputClass="link-card-input"
              placeholder="Smith"
            />
          </IonItem>
          <ErrorText color="danger">
            {formErrors.lastName && formErrors.lastName.message}
          </ErrorText>

          <IonItem>
            <IonLabel position="stacked">
              Credit Card Number <IonText color="danger">*</IonText>
            </IonLabel>
            <TextFieldInput
              name="cardNumber"
              type="text"
              control={control}
              inputClass="link-card-input"
              placeholder="1234 5678 1234 5678"
              mask="cardNumber"
              onChange={(e: CustomEvent) => {
                const { type } = getCardInfo(e.detail.value);
                setCardType(type);
              }}
            />
          </IonItem>
          <CardText>Your card will not be charged at this time</CardText>
          <ErrorText color="danger">
            {formErrors.cardNumber && formErrors.cardNumber.message}
          </ErrorText>

          <IonItem>
            <IonLabel position="stacked">
              Expiration <IonText color="danger">*</IonText>
            </IonLabel>
            <TextFieldInput
              name="expiration"
              placeholder="12/24"
              type="text"
              control={control}
              inputClass="link-card-input"
              mask="expiration"
            />
          </IonItem>
          <ErrorText color="danger">
            {formErrors.expiration && formErrors.expiration.message}
          </ErrorText>

          <IonItem>
            <IonLabel position="stacked">
              CVV <IonText color="danger">*</IonText>
            </IonLabel>
            <TextFieldInput
              name="cvv"
              type="text"
              control={control}
              inputClass="link-card-input"
              placeholder="123"
              mask="cvv"
            />
          </IonItem>
          <ErrorText color="danger">
            {formErrors.cvv && formErrors.cvv.message}
          </ErrorText>

          <IonItem>
            <IonLabel position="stacked">
              Billing Zip <IonText color="danger">*</IonText>
            </IonLabel>
            <TextFieldInput
              name="zip"
              type="text"
              control={control}
              inputClass="link-card-input"
              placeholder="12345"
              mask="zip"
            />
          </IonItem>
          <ErrorText color="danger">
            {formErrors.zip && formErrors.zip.message}
          </ErrorText>
        </IonList>
        <AcceptTermsWrapper>
          <Controller
            control={control}
            name="acceptTerms"
            render={(props) => {
              return (
                <StyledCheckbox
                  checked={props.value}
                  onIonChange={(e) => {
                    props.onChange(e.detail.checked);
                  }}
                />
              );
            }}
          />
          <WaiverText card={cardType} />
        </AcceptTermsWrapper>
        <IonButton
          className="modal-button"
          type="submit"
          expand="block"
          disabled={fetching}
        >
          {fetching ? (
            <IonSpinner title="button-spinner" name="crescent" />
          ) : (
            'ADD'
          )}
        </IonButton>
      </form>
      <IonAlert
        isOpen={Boolean(cardTokenError || postConsumerCardError)}
        cssClass="modal-dark-mode"
        header="Card Linking Error"
        message={errorMessage}
        buttons={['OK']}
      />
    </IonContent>
  );
};

export default LinkCardForm;
