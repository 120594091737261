import { Store } from './stores-types';
import { BaseMap } from '../../types/generic';
import { actionTypes } from '../actions/actions';
import { Action } from '../actions/actions-types';
import { storesActionTypes } from './stores-actions';

export const storesInitialState = () => ({} as BaseMap<Store>);

export const storesReducer = (
  state = storesInitialState(),
  action: Action<Store>
): any => {
  switch (action.type) {
    case storesActionTypes.SET_STORE:
      return { ...state, [action.payload.id]: action.payload };
    case actionTypes.CLEAR_STATE:
      return storesInitialState();

    default:
      return state;
  }
};
