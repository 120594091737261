import Loadable from 'react-loadable';

export const ResetPasswordPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "reset-password-page" */
      /* webpackMode: "lazy" */
      './ResetPasswordPage'
    ),
  loading: () => null
});
