import React, { useEffect, Fragment, useRef } from 'react';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import {
  IonItem,
  IonLabel,
  IonThumbnail,
  IonIcon,
  IonListHeader,
  IonSkeletonText,
  IonList,
  IonImg,
  IonNote,
  IonItemSliding,
  IonItemOptions,
  IonItemOption
} from '@ionic/react';
import { addCircleOutline, lockClosed, trashOutline } from 'ionicons/icons';
import AlertWithImage from '../components/AlertWithImage';
import emptyState from '../images/wallet-empty-state.svg';
import walletAdded from '../images/wallet-added.svg';
import { routes } from '../shared/constants';

import {
  useGetConsumer,
  useConsumerCardData
} from '../features/consumer/consumer-resolver';
import {
  useDeleteWallet,
  useGetWallet
} from '../features/wallet/wallet-resolver';
import { useGetConsumerStores } from '../features/consumer-stores/consumer-stores-resolver';
import { useWalletNotifications } from '../features/notifications/notifications-resolver';
import { NoStoresEmptyState } from '../components/NoStoresEmptyState';
import { Campaign } from '../features/campaigns/campaigns-types';
import { useAppState } from '../shared/AppContext';
import { setNotificationWallet } from '../features/notifications/notifications-actions';
import { SectionHeader } from '../components/layout/SectionHeader';
import { removeWallet } from '../features/wallet/wallet-actions';
import { FetchError } from '../features/fetch/fetch-types';
import { PageLayout } from '../components/layout/PageLayout';
import NoOffersEmptyState from '../components/NoOffersEmptyState';

const GroupLabel = styled(IonLabel)`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 29px;
  margin-bottom: 6px;
`;

const CampaignThumbnail = styled(IonThumbnail)`
  width: 76px;
  height: auto;
`;

const CampaignHeader = styled.h2`
  white-space: break-spaces;
  font-size: 17px !important;
  font-weight: 800 !important;
`;

const CampaignAutoHeader = styled.h3`
  font-size: 14px !important;
  white-space: break-spaces;
  font-weight: 800 !important;
  color: var(--ion-color-success);
  text-transform: uppercase;
`;

const CampaignSubHeader = styled.h3`
  font-size: 14px !important;
  white-space: break-spaces;
  color: var(--description-text);
`;

const ExpiredText = styled.p`
  color: var(--ion-color-danger);
  font-size: 10px;
`;

const AddLink = styled.a`
  width: 100%;
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ion-color-light-contrast);
  ion-icon {
    font-size: 28px;
    padding-right: 10px;
  }
`;

const StyledList = styled(IonList)`
  padding-bottom: 0px !important;
`;

const renderSkeleton = () => (
  <IonList>
    <SectionHeader>
      <IonSkeletonText animated style={{ width: '40%', height: '20px' }} />
    </SectionHeader>

    {Array.from({ length: 3 }, (a, i) => (
      <Fragment key={i}>
        <IonListHeader>
          <IonSkeletonText animated style={{ width: '50%' }} />
        </IonListHeader>

        {Array.from({ length: 3 }, (b, j) => (
          <IonItem detail={false} lines="full" key={`${i}${j}`}>
            <CampaignThumbnail slot="start">
              <IonSkeletonText animated style={{ height: '40px' }} />
            </CampaignThumbnail>
            <IonLabel>
              <CampaignHeader>
                <IonSkeletonText animated style={{ width: '70%' }} />
              </CampaignHeader>

              <CampaignSubHeader>
                <IonSkeletonText animated style={{ width: '90%' }} />
              </CampaignSubHeader>
            </IonLabel>
          </IonItem>
        ))}
      </Fragment>
    ))}
  </IonList>
);

const WalletPage: React.FC = () => {
  let walletMap: { [key: number]: Campaign[] } = {};
  const { dispatch } = useAppState();
  const { first: firstNotification } = useWalletNotifications();
  const cardData = useConsumerCardData();
  const [
    { isLoading: isLoadingConsumer, error: consumerError },
    getConsumer
  ] = useGetConsumer();
  const [
    { data: wallet, isLoading: isLoadingWallet, error: walletError },
    getWallet
  ] = useGetWallet();
  const [
    { data: stores, isLoading: isLoadingStores, error: storesError },
    getConsumerStores
  ] = useGetConsumerStores();
  const [, deleteWallet] = useDeleteWallet();

  const campaignRef = useRef<HTMLIonItemSlidingElement>(null);

  // TODO: refactor useDelete to support "success action" parameter
  const removeFromWallet = async (campaignId: number) => {
    if (campaignRef.current != null) {
      campaignRef.current.close();
    }
    const result = await deleteWallet({ campaignId });
    if (result && !(result as FetchError).errorCode) {
      dispatch(removeWallet({ id: campaignId }));
    }
  };

  useEffect(
    () => {
      getConsumer();
      getWallet();
      getConsumerStores();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleRefresh = async () => {
    const options = { refresh: true };
    await Promise.all([getWallet(options), getConsumerStores(options)]);
  };

  if (wallet) {
    walletMap = _.groupBy(wallet, 'storeId');
  }

  const error = consumerError || walletError || storesError;
  const loading = isLoadingConsumer || isLoadingWallet || isLoadingStores;
  const hasWalletAndStores = stores.length !== 0 && wallet.length !== 0;

  return (
    <PageLayout
      error={error}
      loading={loading}
      skeleton={renderSkeleton}
      onRefresh={handleRefresh}
      fullscreen={!hasWalletAndStores}
    >
      {hasWalletAndStores && (
        <>
          <AlertWithImage
            open={firstNotification}
            header="When You're Ready to Shop"
            alertMessage="We've automatically added some offers to your wallet that you can use right now."
            image={walletAdded}
            buttons={[
              {
                text: 'Get Started',
                role: 'cancel',
                handler: () => {
                  dispatch(setNotificationWallet(false));
                }
              }
            ]}
          />
          <StyledList>
            <SectionHeader>My Wallet</SectionHeader>
            {stores.map((store) => (
              <Fragment key={store.id}>
                {walletMap[store.id] && (
                  <>
                    <IonListHeader>
                      <GroupLabel>{store.name}</GroupLabel>
                    </IonListHeader>
                    {walletMap[store.id] &&
                      walletMap[store.id].map((campaign) => (
                        <IonItemSliding key={campaign.id} ref={campaignRef}>
                          {campaign.registrationType === 'MANUAL' && (
                            <IonItemOptions side="end">
                              <IonItemOption
                                color="danger"
                                onClick={() => removeFromWallet(campaign.id)}
                              >
                                <IonIcon slot="icon-only" icon={trashOutline} />
                              </IonItemOption>
                            </IonItemOptions>
                          )}
                          <IonItem
                            key={campaign.id}
                            routerLink={`${routes.wallet}/${store.id}/${campaign.id}`}
                            detail={false}
                            lines="full"
                          >
                            <CampaignThumbnail slot="start">
                              <IonImg src={campaign.content.fanPageImage} />
                            </CampaignThumbnail>
                            <IonLabel>
                              <CampaignHeader>
                                {campaign.content.consumerOfferTitle ||
                                  campaign.offer.name}
                              </CampaignHeader>
                              {campaign.registrationType !== 'MANUAL' && (
                                <CampaignAutoHeader>
                                  Auto Added
                                </CampaignAutoHeader>
                              )}
                              <CampaignSubHeader>
                                {campaign.content.fanPageOfferText}
                              </CampaignSubHeader>
                            </IonLabel>

                            {!cardData ||
                              (cardData.length === 0 && (
                                <IonNote slot="end">
                                  <IonIcon icon={lockClosed} />
                                </IonNote>
                              ))}

                            {cardData &&
                              cardData.length > 0 &&
                              campaign.content.endDate && (
                                <IonNote slot="end">
                                  <ExpiredText>
                                    EXP{' '}
                                    {moment(campaign.content.endDate).format(
                                      'M/D'
                                    )}
                                  </ExpiredText>
                                </IonNote>
                              )}
                          </IonItem>
                        </IonItemSliding>
                      ))}
                  </>
                )}
              </Fragment>
            ))}

            <IonItem key="add" lines="none" color="light">
              <AddLink href={`/tabs/${routes.home}`}>
                <IonIcon icon={addCircleOutline} />
                <p>Add more offers</p>
              </AddLink>
            </IonItem>
          </StyledList>
        </>
      )}

      {stores.length === 0 && <NoStoresEmptyState image={emptyState} />}
      {stores.length > 0 && wallet && wallet.length === 0 && (
        <NoOffersEmptyState />
      )}
    </PageLayout>
  );
};

export default WalletPage;
