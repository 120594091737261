import React from 'react';
import NoRewardsEmptyState from '../components/NoRewardsEmptyState';
import { PageLayout } from '../components/layout/PageLayout';

interface Props {
  backRoute: string;
}

const HowPlinkWorksPage: React.FC<Props> = ({ backRoute }) => {
  return (
    <PageLayout backRoute={backRoute}>
      <NoRewardsEmptyState />
    </PageLayout>
  );
};

export default HowPlinkWorksPage;
