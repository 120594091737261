import { DateTime } from 'luxon';
import { TransactionType } from '../features/transactions/transactions-types';

export const formatDatetime = (str: string): string =>
  DateTime.fromISO(str).toLocaleString();

export const formatTransactionType = (type: TransactionType) => {
  switch (type) {
    case TransactionType.SETTLEMENT:
      return 'Complete';
    case TransactionType.AUTHORIZATION:
      return 'Pending';
  }
  return '';
};
