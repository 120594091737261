import { StoreSummary } from './consumer-stores-types';
import { actionTypes } from '../actions/actions';
import { Action } from '../actions/actions-types';
import { consumerStoresActionTypes } from './consumer-stores-actions';

export const consumerStoresInitialState = () => [] as StoreSummary[];

export const consumerStoresReducer = (
  state = consumerStoresInitialState(),
  action: Action<StoreSummary[]>
): any => {
  switch (action.type) {
    case consumerStoresActionTypes.SET_CONSUMER_STORES: {
      return action.payload;
    }
    case actionTypes.CLEAR_STATE:
      return consumerStoresInitialState();

    default:
      return state;
  }
};
