import { usePost, useGet, useDelete } from '../fetch/fetch-resolver';
import { Campaign } from '../campaigns/campaigns-types';
import { setWallet } from './wallet-actions';

export const usePostWallet = (campaignId?: number) => {
  const url = `wallet`;
  return usePost({ url, initialData: { campaignId } });
};

export const useGetWallet = () => {
  return useGet<Campaign[]>({
    url: 'wallet',
    version: 'v2',
    action: setWallet,
    selector: (state) => state.wallet
  });
};

export const useDeleteWallet = () => {
  const url = `wallet/:campaignId`;
  return useDelete({ url, version: 'v2' });
};
