import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { LDProps } from 'launchdarkly-react-client-sdk/lib/withLDConsumer';
import { IonButton } from '@ionic/react';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import bg from '../images/login-bg.png';
import plinkLogo from '../images/plink-logo.png';
import plinkLogoV2 from '../images/plink-logo-v2.svg';
import welcomeCard from '../images/welcome-card.svg';
import { useURLQuery } from '../features/query/query-resolver';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  background: url('${bg}');
  background-size: cover;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  transform: scale(1.01);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  width: 90%;
`;

const Widget = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SignUpButton = styled(IonButton)<{
  textTransform: string;
  fontWeight: string;
}>`
  text-transform: ${({ textTransform }) => textTransform};
  font-weight: ${({ fontWeight }) => fontWeight};
  background-color: white;
  color: black;
  border-radius: 10px;
  margin-left: 0;
  margin-right: 0;
`;

const LogInButton = styled(IonButton)<{
  textTransform?: string;
  fontWeight: string;
}>`
  text-transform: ${({ textTransform }) => textTransform};
  font-weight: ${({ fontWeight }) => fontWeight};
  background-color: rgba(243, 242, 255, 0.3);
  border-radius: 10px;
  margin-left: 0;
  margin-right: 0;
`;

const StyledLogo = styled.img`
  margin-bottom: 3rem;
  width: 10em;
`;

const StyledLogo2 = styled.img`
  margin-bottom: 1rem;
`;

const WelcomeCard = styled.img`
  margin-bottom: 3rem;
  margin: 0;
`;

const SaveThousandsText = styled.p`
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 0;
  color: white;
`;

const ActivateText = styled.p`
  font-weight: bold;
  font-size: 1.2em;
  margin-top: 5px;
  color: white;
`;

const Welcome: React.FC<LDProps> = ({ flags }) => {
  const { storeURLQuery } = useURLQuery();
  useEffect(
    () => {
      // storing URL query params if exists.
      storeURLQuery();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const { push } = useHistory();
  return (
    <Page>
      <Content>
        <Widget>
          {flags?.appV2 ? (
            <>
              <StyledLogo2 src={plinkLogoV2} alt="Plink Logo" />
              <WelcomeCard src={welcomeCard} alt="Save thousands" />
              <SaveThousandsText>Save $1000&apos;s</SaveThousandsText>
              <ActivateText>by activating Plink.</ActivateText>
            </>
          ) : (
            <StyledLogo src={plinkLogo} alt="Plink Logo" />
          )}

          <SignUpButton
            color="signup"
            expand="block"
            onClick={() => push('/signup')}
            textTransform={flags?.appV2 ? 'none' : 'uppercase'}
            fontWeight={flags?.appV2 ? '500' : 'bold'}
          >
            Sign up
          </SignUpButton>

          <LogInButton
            color="login"
            expand="block"
            onClick={() => push('/login')}
            textTransform={flags?.appV2 ? 'none' : 'uppercase'}
            fontWeight={flags?.appV2 ? '500' : 'bold'}
          >
            Log in
          </LogInButton>
        </Widget>
      </Content>
    </Page>
  );
};

export default withLDConsumer()(Welcome);
