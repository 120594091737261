import Loadable from 'react-loadable';
import React from 'react';
import { useReadyFlags } from '../../utils/hooks';

const LoginPageV1 = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "login-page-v1" */
      /* webpackMode: "lazy" */
      './LoginPageV1'
    ),
  loading: () => null
});
const LoginPageV2 = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "login-page-v2" */
      /* webpackMode: "lazy" */
      './LoginPageV2'
    ),
  loading: () => null
});

export const LoginPage: React.FC = () => {
  const flags = useReadyFlags();
  const { loading, appV2 } = flags;
  if (loading) return null;
  return appV2 ? <LoginPageV2 /> : <LoginPageV1 />;
};
