import React from 'react';
import { IonItem, IonList } from '@ionic/react';
import { useAuth } from '../features/auth/auth-resolver';
import { routes } from '../shared/constants';
import { PageLayout } from '../components/layout/PageLayout';
import { useReadyFlags } from '../utils/hooks';
import { useConsumerCardData } from '../features/consumer/consumer-resolver';

const MorePage: React.FC = () => {
  const { ready: flagsReady, enablePlaid = false } = useReadyFlags();

  const auth = useAuth();
  const cardData = useConsumerCardData();
  const fanstoreUrl = `${process.env.REACT_APP_CONSUMER_PORTAL_URL}/fanstore`;
  const fanstoreAuthUrl = `${process.env.REACT_APP_AUTH_URL}?id=${auth.id}&redirectUrl=${fanstoreUrl}`;
  const manageCardsUrl = `${process.env.REACT_APP_LEGACY_URL}/manage-cards.php`;
  const manageCardsAuthUrl = `${process.env.REACT_APP_AUTH_URL}?id=${auth.id}&redirectUrl=${manageCardsUrl}`;

  if (!flagsReady) return null;

  return (
    <PageLayout>
      <IonList>
        <IonItem routerLink={routes.howPlinkWorks} detail={false}>
          How Plink Works
        </IonItem>
        <IonItem routerLink={routes.findBusiness} detail={false}>
          Find a Business in Your Area
        </IonItem>
        <IonItem
          href={fanstoreAuthUrl}
          detail={false}
          target="_blank"
          rel="noopener noreferrer"
        >
          Redeem Fancoins
        </IonItem>
        {cardData.length > 0 && (
          <IonItem
            href={manageCardsAuthUrl}
            detail={false}
            target="_blank"
            rel="noopener noreferrer"
          >
            Manage Cards
          </IonItem>
        )}
        {enablePlaid && (
          <IonItem routerLink={routes.connectAccount} detail={false}>
            Manage Linked Accounts
          </IonItem>
        )}
        <IonItem routerLink={routes.nominateBusiness} detail={false}>
          Nominate a Business
        </IonItem>
        <IonItem routerLink={routes.faqs} detail={false}>
          FAQs
        </IonItem>
        <IonItem routerLink={routes.howFancoinsWork} detail={false}>
          How Fancoins Work
        </IonItem>
        <IonItem routerLink={routes.privacyPolicy} detail={false}>
          Privacy Policy
        </IonItem>
      </IonList>
      <p style={{ textAlign: 'center' }}>v {process.env.REACT_APP_VERSION}</p>
    </PageLayout>
  );
};

export default MorePage;
