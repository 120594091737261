import React from 'react';
import { IonRouterOutlet } from '@ionic/react';
import { Route, Switch } from 'react-router-dom';
import { IonReactRouter } from '@ionic/react-router';
import PrivateRoute from '../components/PrivateRoute';
import LogoutPage from '../pages/LogoutPage';
import TabsPage from '../pages/TabsPage';
import { SignupPage } from '../pages/SignupPage';
import { LoginPage } from '../pages/LoginPage';
import { ForgotPasswordPage } from '../pages/ForgotPasswordPage';
import { ResetPasswordPage } from '../pages/ResetPassword';
import Welcome from '../pages/WelcomePage';
import Widget from '../components/Widget';
import RootPage from '../pages/RootPage';
import HomePage from '../pages/HomePage';
import { routes } from './constants';
import { StorePage } from '../pages/StorePage';
import StoreInfoPage from '../pages/StoreInfoPageV2';
import { CampaignPage } from '../pages/CampaignPage';
import PublicRoute from '../components/PublicRoute';
import LoadingProgram from '../pages/LoadingProgram';
import { Onboarding } from '../pages/OnboardingPage';

const Router: React.FC = () => (
  <IonReactRouter>
    <Route path="/logout" component={LogoutPage} exact />
    <Route path="/widget" component={Widget} />

    <IonRouterOutlet id="main">
      <Switch>
        <PrivateRoute path="/tabs" component={TabsPage} />

        <PublicRoute
          path="/signup"
          component={SignupPage}
          exact
          routerDirection="back"
        />

        <PublicRoute
          path="/login"
          component={LoginPage}
          exact
          routerDirection="back"
        />

        <PublicRoute
          path="/forgot"
          component={ForgotPasswordPage}
          exact
          routerDirection="back"
        />

        <PublicRoute
          path="/reset"
          component={ResetPasswordPage}
          exact
          routerDirection="back"
        />

        <PublicRoute path="/welcome" component={Welcome} exact />
        <Route path="/onboarding" component={Onboarding} exact />

        <Route path={`/widget/${routes.home}`} component={HomePage} exact />

        <PrivateRoute
          path="/loading-program"
          component={LoadingProgram}
          exact
        />

        <PrivateRoute
          path={`/widget/${routes.storesHome}`}
          component={StorePage}
          exact
        />

        <PrivateRoute
          path={`/widget/${routes.storeInfoHome}`}
          component={StoreInfoPage}
        />
        <PrivateRoute
          path={`/widget/${routes.storeCampaignsHome}`}
          component={CampaignPage}
        />

        <PrivateRoute
          path={`/widget/${routes.storeCampaignsHome}`}
          component={CampaignPage}
          exact
        />

        <PrivateRoute
          path={`widget/${routes.storeCampaignsHome}/info`}
          component={StoreInfoPage}
        />
      </Switch>

      <Route exact path="/" component={RootPage} />
    </IonRouterOutlet>
  </IonReactRouter>
);

export default Router;
