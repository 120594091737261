import { IonList, IonItem, IonIcon, IonSkeletonText } from '@ionic/react';
import React, { useEffect } from 'react';
import {
  call,
  globe,
  mail,
  navigate,
  logoInstagram,
  logoFacebook,
  logoTwitter
} from 'ionicons/icons';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Map from '../components/Map';
import { useGetStore } from '../features/stores/stores-resolver';
import { PageLayout } from '../components/layout/PageLayout';
import { StoreSubheader } from '../components/layout/StoreSubheader';

const googleMapUrl = 'http://maps.google.com/?q=';

const StoreList = styled(IonList)`
  padding-top: 0px;
  padding-bottom: 0px;
`;

const StoreMapItem = styled(IonItem)`
  padding: 20px 10px 20px 20px;
  --inner-border-width: 0px;

  ::part(native) {
    padding-left: 0px;
  }
`;

const StoreInfoItem = styled(IonItem)`
  font-size: 14px;
`;

const StoreSocialItem = styled(IonItem)`
  padding-top: 12px;
  --inner-border-width: 0px;
`;

const StoreDescItem = styled(IonItem)`
  font-size: 16px;
  padding: 20px 10px 20px 0px;
  line-height: 21px;
  --inner-border-width: 0px;
`;

const InfoIcon = styled(IonIcon)`
  font-size: 18px;
`;

const SocialButton = styled.a`
  color: white;
  border-radius: 19px;
  height: 38px;
  width: 38px;
  font-size: 20px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const renderSkeleton = () => (
  <IonList id="skeleton">
    <StoreMapItem>
      <IonSkeletonText animated style={{ height: '168px' }} />
    </StoreMapItem>
    {Array.from({ length: 4 }, (_, i) => (
      <StoreInfoItem key={i}>
        <IonSkeletonText animated style={{ width: '80%' }} />
      </StoreInfoItem>
    ))}

    <StoreSocialItem>
      <IonSkeletonText animated style={{ width: '40%', height: '40px' }} />
    </StoreSocialItem>

    <StoreDescItem>
      <IonSkeletonText animated style={{ width: '100%' }} />
    </StoreDescItem>

    <IonSkeletonText animated style={{ width: '100%', height: '200px' }} />
  </IonList>
);

const StoreInfoPage: React.FC = () => {
  const { storeId } = useParams() as any;
  const [{ data: store, isLoading, error }, getStore] = useGetStore(storeId);

  const color =
    store && store.localized.colorfillHexCode
      ? store.localized.colorfillHexCode
      : '#334BC1';

  const fullAddress = store
    ? `${store.streetAddress}, ${store.city} ${store.state} ${store.zipCode}`
    : '';

  useEffect(
    () => {
      getStore();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleRefresh = async () => {
    await getStore({ refresh: true });
  };

  const renderSubheader = () => {
    return <StoreSubheader store={store} loading={isLoading} />;
  };

  return (
    <PageLayout
      error={error}
      loading={isLoading}
      showBack
      onRefresh={handleRefresh}
      skeleton={renderSkeleton}
      subheader={renderSubheader}
    >
      {!isEmpty(store) && (
        <StoreList>
          {store.isStorefront && store.latitude && store.longitude && (
            <StoreMapItem
              href={`${googleMapUrl}${fullAddress}`}
              target="_blank"
              detail={false}
            >
              <Map
                latitude={store.latitude}
                longitude={store.longitude}
                markerColor={color}
              />
            </StoreMapItem>
          )}

          {store.isStorefront && (
            <StoreInfoItem
              href={`${googleMapUrl}${fullAddress}`}
              target="_blank"
              detail={false}
            >
              <InfoIcon icon={navigate} slot="start" style={{ color }} />
              {store.streetAddress}, {store.city} {store.state}
            </StoreInfoItem>
          )}
          {store.phone && (
            <StoreInfoItem href={`tel:${store.phone}`} detail={false}>
              <InfoIcon icon={call} slot="start" style={{ color }} />
              {store.phone}
            </StoreInfoItem>
          )}
          {store.websiteUrl && (
            <StoreInfoItem
              detail={false}
              href={store.websiteUrl}
              target="_blank"
            >
              <InfoIcon icon={globe} slot="start" style={{ color }} />
              {store.websiteUrl}
            </StoreInfoItem>
          )}
          {store.email && (
            <StoreInfoItem detail={false} href={`mailto:${store.email}`}>
              <InfoIcon icon={mail} slot="start" style={{ color }} />
              {store.email}
            </StoreInfoItem>
          )}
          {(store.facebookUrl || store.twitterUrl || store.instagramUrl) && (
            <StoreSocialItem>
              {store.instagramUrl && (
                <SocialButton
                  href={store.instagramUrl}
                  target="_blank"
                  style={{ backgroundColor: color }}
                >
                  <IonIcon icon={logoInstagram} />
                </SocialButton>
              )}

              {store.facebookUrl && (
                <SocialButton
                  href={store.facebookUrl}
                  target="_blank"
                  style={{ backgroundColor: color }}
                >
                  <IonIcon icon={logoFacebook} />
                </SocialButton>
              )}

              {store.twitterUrl && (
                <SocialButton
                  href={store.twitterUrl}
                  target="_blank"
                  style={{ backgroundColor: color }}
                >
                  <IonIcon icon={logoTwitter} />
                </SocialButton>
              )}
            </StoreSocialItem>
          )}
          {store.description && (
            <StoreDescItem>{store.description}</StoreDescItem>
          )}

          {store.files.secondary && (
            <img src={store.files.secondary} alt="banner" />
          )}
        </StoreList>
      )}
    </PageLayout>
  );
};

export default StoreInfoPage;
