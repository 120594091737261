import Loadable from 'react-loadable';
import React from 'react';
import { useReadyFlags } from '../../utils/hooks';

const SignupPageV1 = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "signup-page-v1" */
      /* webpackMode: "lazy" */
      './SignupPageV1'
    ),
  loading: () => null
});
const SignupPageV2 = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "signup-page-v2" */
      /* webpackMode: "lazy" */
      './SignupPageV2'
    ),
  loading: () => null
});

export const SignupPage: React.FC = () => {
  const flags = useReadyFlags();
  const { loading, appV2 } = flags;
  if (loading) return null;
  return appV2 ? <SignupPageV2 /> : <SignupPageV1 />;
};
