import React from 'react';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonLoading
} from '@ionic/react';
import { PlaidHeadline } from './link-account/components/PlaidHeadline';
import { usePlaidState } from '../features/plaid/plaid-resolver';
import { PlaidOauth } from './link-account/components/PlaidOauth';

interface Props {
  backRoute: string;
}

export const LinkAccountPage: React.FC<Props> = ({ backRoute }) => {
  const { loading = false } = usePlaidState();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={backRoute} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <PlaidHeadline />
        <PlaidOauth />
      </IonContent>

      <IonLoading isOpen={loading} message={'Please wait...'} />
    </IonPage>
  );
};
