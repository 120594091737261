import React from 'react';
import styled from 'styled-components';
import { IonButton, IonContent } from '@ionic/react';
import iconCreditCard from '../../images/icon-credit-card.svg';
import iconPurchase from '../../images/icon-purchase.svg';
import iconReward from '../../images/icon-reward.svg';

const StepContent = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  margin: 2em auto;
`;

const StepDescription = styled.div`
  margin-left: 2em;
`;

const StepNumber = styled.p`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 0px;
`;

const StepText = styled.p`
  margin-top: 5px;
  color: var(--description-text);
`;

const ModalTitle = styled.h1`
  text-align: center;
  font-size: 22px;
  font-weight: bold;
`;

const LinkCardSteps: React.FC<{
  onButtonClick: () => void;
}> = ({ onButtonClick }) => {
  return (
    <IonContent className="modal-dark-mode">
      <ModalTitle>
        Link an eligible payment card to unlock every offer!
      </ModalTitle>
      <StepContent>
        <img src={iconCreditCard} alt="Step 1 Card Link" />
        <StepDescription>
          <StepNumber>STEP 1</StepNumber>
          <StepText>Add a credit or debit card</StepText>
        </StepDescription>
      </StepContent>
      <StepContent>
        <img src={iconPurchase} alt="Step 2 Card Link" />
        <StepDescription>
          <StepNumber>STEP 2</StepNumber>
          <StepText>Use it to buy from local businesses</StepText>
        </StepDescription>
      </StepContent>
      <StepContent>
        <img src={iconReward} alt="Step 3 Card Link" />
        <StepDescription>
          <StepNumber>STEP 3</StepNumber>
          <StepText>Get rewarded!</StepText>
        </StepDescription>
      </StepContent>
      <IonButton
        className="modal-button"
        expand="block"
        onClick={onButtonClick}
      >
        LINK CARD
      </IonButton>
    </IonContent>
  );
};

export default LinkCardSteps;
