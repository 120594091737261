import { useGet, usePost, usePatch, useDelete } from '../fetch/fetch-resolver';
import {
  PlaidState,
  PlaidPublicToken,
  PlaidResetToken,
  PlaidOauthState
} from './plaid-types';
import { useAuth } from '../auth/auth-resolver';
import {
  deletePlaidToken,
  setPlaidAccessToken,
  setPlaidInfo,
  setPlaidLinkToken,
  setPlaidOauthToken
} from './plaid-actions';
import { useAppState } from '../../shared/AppContext';

export const useGetPlaidInfo = <PlaidInfo>() => {
  const { consumerId } = useAuth();
  return useGet({
    url: 'consumers/:consumerId/plaid/access-token',
    version: 'v2',
    initialParams: { consumerId },
    action: setPlaidInfo,
    selector: (state) => state.plaid
  });
};

export const usePostCreateTokenLink = <PlaidInfo>() => {
  const { consumerId } = useAuth();
  return usePost<PlaidInfo>({
    url: `consumers/${consumerId}/plaid/access-token`,
    version: 'v2',
    action: [setPlaidLinkToken, setPlaidOauthToken]
  });
};

export const usePatchSetAccessToken = <PlaidInfo>() => {
  const { consumerId } = useAuth();
  return usePatch<PlaidPublicToken | PlaidResetToken>({
    url: `consumers/${consumerId}/plaid/access-token`,
    version: 'v2',
    action: setPlaidAccessToken
  });
};

export const useDeletePlaidToken = <PlaidInfo>() => {
  const { consumerId } = useAuth();
  return useDelete<PlaidInfo>({
    url: `consumers/${consumerId}/plaid/access-token/:tokenId`,
    version: 'v2',
    action: deletePlaidToken
  });
};

export const usePlaidState = (): PlaidState =>
  (useAppState()?.state?.plaid ?? {}) as PlaidState;

export const usePlaidOauthState = (): PlaidOauthState =>
  useAppState()?.state?.plaidOauth as PlaidOauthState;
