import { ConsumerPurchase } from './purchases-types';
import { actionTypes } from '../actions/actions';
import { Action } from '../actions/actions-types';
import { purchasesActionTypes } from './purchases-actions';

export const purchasesInitialState = () => [] as ConsumerPurchase[];

export const purchasesReducer = (
  state = purchasesInitialState(),
  action: Action<ConsumerPurchase>
): any => {
  switch (action.type) {
    case purchasesActionTypes.SET_CONSUMER_PURCHASES: {
      return action.payload;
    }
    case purchasesActionTypes.ADD_PURCHASE: {
      const { id, campaignId, storeId, total, inventory } = action.payload;
      const fulfillments: any[] = [];

      inventory.forEach((item: any) => {
        if (item.fulfillmentId) {
          fulfillments.push({
            id: item.fulfillmentId,
            consumerPurchaseId: id,
            inventoryId: item.id
          });
        }
      });

      const purchase = {
        id,
        campaignId,
        storeId,
        total,
        fulfillments
      };

      return [...state, purchase];
    }

    case actionTypes.CLEAR_STATE:
      return purchasesInitialState();

    default:
      return state;
  }
};
