import React from 'react';
import { IonInput } from '@ionic/react';
import { Controller, Control } from 'react-hook-form';
import { maskMapping } from '../../shared/form-helper';

type InputProps = {
  name: string;
  type:
    | 'number'
    | 'time'
    | 'text'
    | 'date'
    | 'email'
    | 'password'
    | 'search'
    | 'tel'
    | 'url'
    | undefined;
  control: Control;
  rules?: any;
  placeholder?: string;
  onChange?: any;
  onBlur?: any;
  inputClass?: string;
  mask?: 'phone' | 'cardNumber' | 'cvv' | 'expiration' | 'zip';
  autocapitalize?: string;
  value?: any;
};

const TextFieldInput: React.FC<InputProps> = ({
  name,
  type,
  control,
  rules,
  placeholder,
  onChange,
  onBlur,
  inputClass,
  mask,
  autocapitalize,
  value
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={value}
      render={(props) => {
        return (
          <IonInput
            autocapitalize={autocapitalize}
            class={inputClass || 'sign-up-default-input'}
            type={type}
            value={props.value}
            onIonChange={(e) => {
              if (onChange) onChange(e);
              props.onChange(e);
              if (mask) {
                if (!e.detail.value) return;
                const maskedValue = maskMapping[mask](e.detail.value);
                control.setValue(name, maskedValue);
              } else {
                control.setValue(name, e.detail.value);
              }
            }}
            onIonBlur={() => {
              if (onBlur) onBlur();
              props.onBlur();
            }}
            placeholder={placeholder}
          />
        );
      }}
    />
  );
};

export default TextFieldInput;
