import React, { useEffect, useState } from 'react';
import {
  PlaidLinkOnExit,
  PlaidLinkOnSuccess,
  usePlaidLink
} from 'react-plaid-link';
import { useHistory, useParams } from 'react-router-dom';
import { useAppState } from '../../../shared/AppContext';
import {
  resetPlaidToken,
  setPlaidLoading
} from '../../../features/plaid/plaid-actions';
import {
  usePlaidState,
  usePostCreateTokenLink,
  usePatchSetAccessToken
} from '../../../features/plaid/plaid-resolver';
import { plaidReturnRoutes } from '../../../shared/constants';
import { PlaidToken } from '../../../features/plaid/plaid-types';
import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link/src/types/index';

interface BaseProps {
  currentAccessToken?: string | undefined;
}

interface InnerLinkProps extends BaseProps {
  onSuccess: (token: any, meta: any) => void;
  onExit: (err: any, meta: any) => void;
}

interface Props extends BaseProps {
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  onExit?: () => void;
  token?: PlaidToken;
}

const backRoutes: { [k: string]: string } = plaidReturnRoutes;

const PlaidInnerLink = ({ onSuccess, onExit }: InnerLinkProps) => {
  const { plaidLinkToken } = usePlaidState();
  const { open, ready } = usePlaidLink({
    onSuccess,
    onExit,
    token: (plaidLinkToken?.linkToken || '') as unknown as string
  });
  useEffect(() => {
    if (ready) open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  return null;
};

export const PlaidLinking = ({
  token,
  open,
  onClose,
  onSuccess: onS,
  onExit: onE
}: Props) => {
  const { dispatch } = useAppState();
  const [openStatus, setOpenStatus] = useState(open);
  const { action, ...params } = useParams() as { [key: string]: string };
  const { push } = useHistory();
  const [{ error: e1, isLoading: l1 }, createTokenLink] =
    usePostCreateTokenLink();
  const [{ error: e2, isLoading: l2 }, setAccessToken] =
    usePatchSetAccessToken();
  const openPlaidLoading = () => dispatch(setPlaidLoading(true));
  const dismissPlaidLoading = () => dispatch(setPlaidLoading(false));

  const { accessToken: currentAccessToken, id: resetTokenId } = token || {};

  const backRoute: string | null =
    action && backRoutes[action]
      ? backRoutes[action].replace(
          /\/:(action|campaignId|storeId)/g,
          (_, param) => `/${params[param] || '0'}`
        )
      : null;

  useEffect(() => {
    if (open) {
      openPlaidLoading();
      createTokenLink({ currentAccessToken });
      setOpenStatus(true);
    } else if (backRoute) {
      push(backRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onSuccess: PlaidLinkOnSuccess = (
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata
  ) => {
    setAccessToken(resetTokenId ? { resetTokenId } : { publicToken, metadata });
    setOpenStatus(false);
    if (onS) onS();
    onClose();
    openPlaidLoading();
    if (backRoute) push(backRoute);
  };

  const onExit: PlaidLinkOnExit = (err: any, metadata: any) => {
    setOpenStatus(false);
    if (err) {
      if (err?.error_code === 'item-no-error' && resetTokenId) {
        setAccessToken({ resetTokenId });
      }
      dismissPlaidLoading();
      if (onE) onE();
      if (backRoute) push(backRoute);
    }
    onClose();
    dispatch(resetPlaidToken());
  };

  return !openStatus || l1 || l2 || e1 || e2 ? null : (
    <PlaidInnerLink onSuccess={onSuccess} onExit={onExit} />
  );
};
