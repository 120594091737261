import {
  Campaign,
  CampaignRecommendationResponse
} from '../campaigns/campaigns-types';
import { BaseMap } from '../../types/generic';
import { storeCampaignsActionTypes } from './store-campaigns-actions';
import { actionTypes } from '../actions/actions';
import { Action } from '../actions/actions-types';

export const storeCampaignsInitialState = () => ({} as BaseMap<Campaign[]>);

export const storeCampaignsReducer = (
  state = storeCampaignsInitialState(),
  action: Action<CampaignRecommendationResponse>
): any => {
  switch (action.type) {
    case storeCampaignsActionTypes.SET_STORE_CAMPAIGNS: {
      if (action.payload && action.payload.campaigns.length) {
        const { storeId, campaigns } = action.payload;
        return storeId ? { ...state, [storeId]: campaigns } : state;
      }
      return state;
    }

    case actionTypes.CLEAR_STATE:
      return storeCampaignsInitialState();

    default:
      return state;
  }
};
