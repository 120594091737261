import React from 'react';
import styled from 'styled-components';
import {
  Campaign,
  CampaignOfferInventory
} from '../../../features/campaigns/campaigns-types';
import { Transaction } from '../../../features/transactions/transactions-types';

const PriceLabel = styled.div`
  color: #00cb6a;
  font-size: 0.875rem;
  font-weight: bold;
`;

const InventoryPicture = styled.div`
  margin-top: 8px;
  img {
    width: 3.75rem;
  }
`;

const stripDecimals = (value?: string): string =>
  String(value).replace(/\.\d*$/, '');

interface Props {
  campaign: Campaign;
  transaction: Transaction;
}

export const TransactionItemReward = ({ campaign, transaction }: Props) => {
  const offerType: string = campaign?.offer?.type ?? '';
  const inventoryItems = campaign?.inventory?.items ?? [];
  const items: CampaignOfferInventory[] = Array.isArray(inventoryItems)
    ? inventoryItems
    : [inventoryItems];
  // Take the first inventory image to display.
  switch (offerType) {
    case 'cashback-percent':
      const percentBack: number = parseFloat(campaign?.offer?.value ?? 0);
      const totalAmount: number = transaction.data.amount || 0;
      const amountBack: string = (percentBack / 100 * totalAmount).toFixed(2);
      return (
        <>
          <PriceLabel data-testid="reward-label">
            +${amountBack}
          </PriceLabel>
          <PriceLabel>Cash back</PriceLabel>
        </>
      );
    case 'discount-percent':
    case 'discount-amount':
      const firstCredit = items?.find(({ type }) => type === 'store-credit');
      const creditImage = firstCredit?.imageUrl;
      const creditValue = firstCredit?.price;
      if (!creditValue) return null;
      return (
        <>
          <PriceLabel data-testid="reward-label">
            +${stripDecimals(creditValue)} credit
          </PriceLabel>
          {creditImage && (
            <InventoryPicture>
              <img
                data-testid="reward-picture"
                alt="e-gift"
                src={creditImage}
              />
            </InventoryPicture>
          )}
        </>
      );
    case 'x-for-y':
    case 'giveaway':
      const firstItem = items[0];
      const firstImage = firstItem?.imageUrl;
      const firstPrice = firstItem?.price;
      if (!firstPrice) return null;
      return (
        <>
          <PriceLabel data-testid="reward-label">
            +${stripDecimals(firstPrice)} e-gift
          </PriceLabel>
          {firstImage && (
            <InventoryPicture>
              <img data-testid="reward-picture" alt="e-gift" src={firstImage} />
            </InventoryPicture>
          )}
        </>
      );
    case 'fancoins':
      return (
        <>
          <PriceLabel data-testid="reward-label">
            +{stripDecimals(campaign.offer.value)}
          </PriceLabel>
          <PriceLabel>Fancoins</PriceLabel>
        </>
      );
    case 'cash-back':
    case 'cashback-amount':
      return (
        <>
          <PriceLabel data-testid="reward-label">
            +${stripDecimals(campaign.offer.value)}
          </PriceLabel>
          <PriceLabel>Cash back</PriceLabel>
        </>
      );
    default:
      return null;
  }
};
