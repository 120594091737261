import React from 'react';
import styled from 'styled-components';
import iconSelectBusiness from '../images/icon-select-business.svg';
import iconSelectOffer from '../images/icon-select-offer.svg';
import iconPurchase from '../images/icon-purchase.svg';
import { SectionHeader } from './layout/SectionHeader';
import { Container, ContainerSecondary } from './layout/styled';
import { shortDeviceMaxHeight } from '../shared/constants';

const StepWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 2em;

  @media (max-height: ${shortDeviceMaxHeight}) {
    img {
      max-height: 80px;
    }
  }
`;

const StepTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2em;

  @media (max-height: ${shortDeviceMaxHeight}) {
    margin-left: 10px;
  }
`;

const StepText = styled.p`
  font-weight: bold;
  font-size: 18px;
  margin: 0;

  @media (max-height: ${shortDeviceMaxHeight}) {
    font-size: 16px;
  }
`;

const StepDescription = styled.p`
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 18px;
  color: var(--description-text);

  @media (max-height: ${shortDeviceMaxHeight}) {
    font-size: 14px;
  }
`;

const ContactUsWrapper = styled(ContainerSecondary)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 26vh;
`;

const NoRewardsEmptyState: React.FC = () => {
  return (
    <>
      <SectionHeader>Start Earning Rewards</SectionHeader>
      <Container>
        <StepWrapper>
          <img src={iconSelectBusiness} alt="Step 1" />
          <StepTextWrapper>
            <StepText>STEP 1</StepText>
            <StepDescription>
              Select one of your connected businesses.
            </StepDescription>
          </StepTextWrapper>
        </StepWrapper>
        <StepWrapper>
          <img src={iconSelectOffer} alt="Step 2" />
          <StepTextWrapper>
            <StepText>STEP 2</StepText>
            <StepDescription>
              Claim or buy an offer of your choice!
            </StepDescription>
          </StepTextWrapper>
        </StepWrapper>
        <StepWrapper>
          <img src={iconPurchase} alt="Step 3" />
          <StepTextWrapper>
            <StepText>STEP 3</StepText>
            <StepDescription>
              Head to the business and make a purchase using your linked card to
              start earning!
            </StepDescription>
          </StepTextWrapper>
        </StepWrapper>
      </Container>

      <ContactUsWrapper>
        <p>Is something wrong?</p>
        <a href="mailto:support@fanbank.com">Contact Us</a>
      </ContactUsWrapper>
    </>
  );
};

export default NoRewardsEmptyState;
