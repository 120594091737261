import { ActionCreator } from '../actions/actions-types';

export const plaidActionTypes = {
  SET_PLAID_INFO: 'setPlaidInfo',
  SET_PLAID_LINK_TOKEN: 'setPlaidLinkToken',
  SET_PLAID_ACCESS_TOKEN: 'setPlaidAccessToken',
  SET_PLAID_OAUTH_TOKEN: 'setPlaidOauthToken',
  CLEAR_PLAID_OAUTH_TOKEN: 'clearPlaidOauthToken',
  SET_PLAID_LOADING: 'setPlaidLoading',
  DELETE_PLAID_TOKEN: 'deletePlaidToken',
  RESET_PLAID_TOKEN: 'resetPlaidToken'
};

export const setPlaidInfo: ActionCreator<Required<string>> = (payload) => ({
  type: plaidActionTypes.SET_PLAID_INFO,
  payload
});

export const setPlaidLinkToken: ActionCreator<Required<string>> = (
  payload
) => ({
  type: plaidActionTypes.SET_PLAID_LINK_TOKEN,
  payload
});

export const setPlaidAccessToken: ActionCreator<Required<string>> = (
  payload
) => ({
  type: plaidActionTypes.SET_PLAID_ACCESS_TOKEN,
  payload
});

export const setPlaidOauthToken: ActionCreator<Required<string>> = (
  payload
) => ({
  type: plaidActionTypes.SET_PLAID_OAUTH_TOKEN,
  payload
});

export const clearPlaidOauthToken: ActionCreator<Required<string>> = () => ({
  type: plaidActionTypes.CLEAR_PLAID_OAUTH_TOKEN
});

export const setPlaidLoading: ActionCreator<Required<boolean>> = (payload) => ({
  type: plaidActionTypes.SET_PLAID_LOADING,
  payload
});

export const deletePlaidToken: ActionCreator<Required<number>> = (payload) => ({
  type: plaidActionTypes.DELETE_PLAID_TOKEN,
  payload
});

export const resetPlaidToken: ActionCreator<void> = () => ({
  type: plaidActionTypes.RESET_PLAID_TOKEN
});
