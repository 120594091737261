import { useLocation } from 'react-router-dom';
import queryString, { ParsedQuery } from 'query-string';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';

// A custom hook that builds on useLocation to parse
// the query string for you.

export function useQuery(): ParsedQuery {
  const { search } = useLocation();
  return queryString.parse(search);
}

interface ReadyLDFlagSet extends LDFlagSet {
  ready: boolean;
  loading: boolean;
}

export const useReadyFlags = (): ReadyLDFlagSet => {
  const client = useLDClient();
  const flags: LDFlagSet = useFlags();
  const [ready, setReady] = useState(false);
  useEffect(() => {
    if (client?.waitUntilReady)
      client.waitUntilReady().then(() => setReady(true));
  });
  return { ...flags, ready, loading: !ready };
};
