import React from 'react';
import { IonAlert, AlertButton } from '@ionic/react';

type AlertProps = {
  open: boolean;
  image: string;
  header: string;
  alertMessage: string;
  buttons: (string | AlertButton)[];
};

const AlertWithImage: React.FC<AlertProps> = ({
  open,
  image,
  header,
  alertMessage,
  buttons
}) => {
  const message = `<div class="alert-content-center">
    <img src="${image}" alt="Alert image" />
    <p class="alert-header">${header}</p>
    <p>${alertMessage}</p>
  </div>
  `;

  return (
    <IonAlert
      isOpen={open}
      cssClass="modal-dark-mode"
      message={message}
      buttons={buttons}
    />
  );
};

export default AlertWithImage;
