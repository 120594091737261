import { Campaign } from '../campaigns/campaigns-types';
import { actionTypes } from '../actions/actions';
import { Action } from '../actions/actions-types';
import { walletActionTypes } from './wallet-actions';

export const walletInitialState = () => [] as Campaign[];

export const walletReducer = (
  state = walletInitialState(),
  action: Action<Campaign | Campaign[]>
) => {
  switch (action.type) {
    case walletActionTypes.SET_WALLET:
      return action.payload;

    case walletActionTypes.ADD_WALLET:
      return [...state, action.payload as Campaign];

    case walletActionTypes.REMOVE_WALLET: {
      return state.filter(
        (campaign) => campaign.id !== (action.payload as Campaign).id
      );
    }

    case actionTypes.CLEAR_STATE:
      return walletInitialState();

    default:
      return state;
  }
};
