import { IonSpinner } from '@ionic/react';
import React from 'react';
import styled from 'styled-components';
import loadingGif from '../images/loading.gif';

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StyledGif = styled.img`
  width: 319px;
  border-radius: 10px;
  margin-bottom: 2em;
`;

const Text = styled.p`
  font-size: 16px;
  color: var(--ion-color-medium);
  width: 200px;
  text-align: center;
  margin-top: 1em;
`;

const LoadingProgram: React.FC = () => {
  return (
    <Centered>
      <StyledGif src={loadingGif} alt="Loading Store Program" />
      <IonSpinner name="lines" />
      <Text>Creating your custom program</Text>
    </Centered>
  );
};

export default LoadingProgram;
