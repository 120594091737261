import { ActionCreator } from '../actions/actions-types';

export const notificationsActionTypes = {
  SET_ALL_NOTIFICATIONS: 'setAllNotifications',
  SET_NOTIFICATION_HOME: 'setNotificationHome',
  SET_NOTIFICATION_WALLET: 'setNotificationWallet'
};

export const setAllNotifications: ActionCreator<boolean> = (payload) => ({
  type: notificationsActionTypes.SET_ALL_NOTIFICATIONS,
  payload
});

export const setNotificationHome: ActionCreator<boolean> = (payload) => ({
  type: notificationsActionTypes.SET_NOTIFICATION_HOME,
  payload
});

export const setNotificationWallet: ActionCreator<boolean> = (payload) => ({
  type: notificationsActionTypes.SET_NOTIFICATION_WALLET,
  payload
});
