import React, { useEffect } from 'react';
import {
  PlaidLinkOnExit,
  PlaidLinkOnSuccess,
  usePlaidLink
} from 'react-plaid-link';
import { useHistory } from 'react-router-dom';
import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link/src/types/index';
import { useQuery } from '../../../utils/hooks';
import {
  usePatchSetAccessToken,
  usePlaidOauthState
} from '../../../features/plaid/plaid-resolver';
import { PlaidOauthState } from '../../../features/plaid/plaid-types';
import { PlaidItems } from './PlaidItems';
import { clearPlaidOauthToken } from '../../../features/plaid/plaid-actions';
import { routes } from '../../../shared/constants';

const PlaidOauthResume = ({ linkToken }: PlaidOauthState) => {
  const { push } = useHistory();
  const [, setAccessToken] = usePatchSetAccessToken();

  const removeQueryParams = () => push(routes.connectAccount);

  const cleanOauth = () => {
    clearPlaidOauthToken();
    removeQueryParams();
  };

  const onSuccess: PlaidLinkOnSuccess = async (
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata
  ) => {
    await setAccessToken({ publicToken, metadata });
    cleanOauth();
  };

  const onExit: PlaidLinkOnExit = () => cleanOauth();

  const { open, ready } = usePlaidLink({
    onSuccess,
    onExit,
    token: (linkToken || '') as unknown as string,
    receivedRedirectUri: window.location.href
  });

  useEffect(() => {
    if (ready) {
      open();
      removeQueryParams();
    }
  });

  return null;
};

export const PlaidOauth = () => {
  const { linkToken } = usePlaidOauthState();
  const { oauth_state_id: oauthToken } = useQuery();
  return linkToken && oauthToken ? (
    <PlaidOauthResume linkToken={linkToken} />
  ) : (
    <PlaidItems />
  );
};
