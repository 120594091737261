import { useGet } from '../fetch/fetch-resolver';
import { Store } from './stores-types';
import { useAppState } from '../../shared/AppContext';
import { setStore } from './stores-actions';

export const useGetStore = (storeId: number) => {
  return useGet<Store>({
    url: 'stores/:storeId',
    version: 'v2',
    action: setStore,
    initialParams: { storeId },
    selector: (state, params) => state.stores[params.storeId]
  });
};

export const useStoreData = (storeId: number): Store => {
  const { state } = useAppState();
  return state.stores[storeId] || {};
};
