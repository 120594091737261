import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  useGetPlaidInfo,
  usePlaidState
} from '../../../features/plaid/plaid-resolver';
import { PlaidItem } from './PlaidItem';
import { setPlaidLoading } from '../../../features/plaid/plaid-actions';
import { useAppState } from '../../../shared/AppContext';

const PlaidTokenDlStyle = styled.dl`
  margin: 0;
  padding: 36px 24px;
`;

export const PlaidItems = () => {
  const { plaidAccessTokens } = usePlaidState();
  const [{ error, isLoading }, getPlaidInfo] = useGetPlaidInfo();
  const { dispatch } = useAppState();

  useEffect(() => {
    getPlaidInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setPlaidLoading(isLoading && !error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return !plaidAccessTokens?.length || error || isLoading ? null : (
    <PlaidTokenDlStyle>
      {plaidAccessTokens.map((token) => (
        <PlaidItem key={token.id} token={token} />
      ))}
    </PlaidTokenDlStyle>
  );
};
