import { transactionsInitialState } from '../features/transactions/transactions-reducer';
import { walletInitialState } from '../features/wallet/wallet-reducer';
import { consumerStoresInitialState } from '../features/consumer-stores/consumer-stores-reducer';
import { storeCampaignsInitialState } from '../features/store-campaigns/store-campaigns-reducer';
import { storesInitialState } from '../features/stores/stores-reducer';
import { consumerInitialState } from '../features/consumer/consumer-reducer';
import { campaignsInitialState } from '../features/campaigns/campaigns-reducer';
import { actionsInitialState } from '../features/actions/actions-reducer';
import { authInitialState } from '../features/auth/auth-reducer';
import { purchasesInitialState } from '../features/purchases/purchases-reducer';
import { notificationsInitialState } from '../features/notifications/notifications-reducer';
import { queryInitialState } from '../features/query/query-reducer';
import { plaidInitialState, plaidOauthInitialState } from '../features/plaid/plaid-reducer';

export const initialState = {
  auth: authInitialState(),
  actions: actionsInitialState(),
  stores: storesInitialState(),
  storeCampaigns: storeCampaignsInitialState(),
  campaigns: campaignsInitialState(),
  consumer: consumerInitialState(),
  consumerStores: consumerStoresInitialState(),
  wallet: walletInitialState(),
  purchases: purchasesInitialState(),
  notifications: notificationsInitialState(),
  transactions: transactionsInitialState(),
  query: queryInitialState(),
  plaid: plaidInitialState(),
  plaidOauth: plaidOauthInitialState()
};

export type AppState = typeof initialState;
