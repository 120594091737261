import React, { useState } from 'react';
import styled from 'styled-components';
import { addCircleOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import { PlaidLinking } from './PlaidLinking';

const PlaidStyledHeader = styled.div`
  padding: 12px 24px;
  display: flex;

  > * {
    display: flex;
    align-items: center;
  }

  h1 {
    margin: 0;
    flex-grow: 1;
  }
  .icon {
    padding: 8px 0 8px 24px !important;
    border: none !important;
    background: transparent !important;
  }
  .link {
    background: transparent !important;
  }
`;

export const PlaidHeadline = () => {
  const [openPlaid, setOpenPlaid] = useState(false);
  return (
    <PlaidStyledHeader>
      <h1>Your Linked Accounts</h1>
      <div className="add-button">
        <IonIcon
          onClick={() => setOpenPlaid(!openPlaid)}
          size="large"
          icon={addCircleOutline}
        />
        <PlaidLinking open={openPlaid} onClose={() => setOpenPlaid(false)} />
      </div>
    </PlaidStyledHeader>
  );
};
