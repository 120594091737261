import React from 'react';
import { IonLabel, IonListHeader } from '@ionic/react';
import styled from 'styled-components';

const Header = styled(IonListHeader)`
  // TODO: create a theme.ts file
  background: #666666;
  color: #ffffff;
  min-height: 2rem;
  line-height: 2rem;
  @media (prefers-color-scheme: dark) {
    & {
      background: #cccccc;
      color: #666666;
    }
  }
`;

const Label = styled(IonLabel)`
  // TODO: change px to rem
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  width: 90%;
  margin: 0;
`;

export interface ListHeaderProps {
  children: React.ReactNode;
}

export const ListHeader: React.FC<ListHeaderProps> = ({
  children,
  ...props
}) => {
  return (
    <Header {...props}>
      <Label>{children}</Label>
    </Header>
  );
};
