import { ActionCreator } from '../actions/actions-types';

export const storesActionTypes = {
  SET_STORE: 'setStore'
};

export const setStore: ActionCreator = (payload) => ({
  type: storesActionTypes.SET_STORE,
  payload
});
