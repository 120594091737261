import {
  IonList,
  IonItem,
  IonListHeader,
  IonButton,
  IonSpinner,
  IonDatetime,
  IonText,
  IonAlert,
  IonSkeletonText
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { PageLayout } from '../components/layout/PageLayout';

import {
  useConsumerData,
  useGetConsumer,
  usePatchConsumer
} from '../features/consumer/consumer-resolver';
import { FetchError } from '../features/fetch/fetch-types';
import { useAppState } from '../shared/AppContext';
import { updateConsumer } from '../features/consumer/consumer-actions';
import { routes } from '../shared/constants';

type FormValues = {
  birthday: 'string';
};

const AccountHeader = styled(IonListHeader)`
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: bold;
  font-size: 18pt;
`;

const SaveButton = styled(IonButton)`
  margin-left: 20px;
  margin-right: 20px;
`;

const ErrorText = styled(IonText)`
  padding-left: 20px;
`;

const AccountList = styled(IonList)`
  padding-right: 20px;
`;

const BirthdayField = styled.input`
  border: 0px;
  background-color: transparent;
`;

const ConsumerBirthdayPage: React.FC = () => {
  const { dispatch } = useAppState();
  const history = useHistory();
  const [
    { error: consumerError, isLoading: isLoadingConsumer },
    getConsumer
  ] = useGetConsumer();
  const consumerData = useConsumerData();
  const { handleSubmit, errors: formErrors } = useForm<FormValues>();

  useEffect(
    () => {
      getConsumer();
      setSelectedDate(consumerData.birthday);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [consumerData]
  );

  const [
    { isLoading: isLoadingUpdate, error: updateError },
    doPatchConsumer
  ] = usePatchConsumer({
    consumerId: consumerData.id
  });

  const onSubmit = async () => {
    const patchData = {
      consumerId: consumerData.id,
      birthday: selectedDate as string
    };
    const patchRes = await doPatchConsumer(patchData);
    if (patchRes && !(patchRes as FetchError).errorCode) {
      dispatch(updateConsumer(patchData));
      history.goBack();
    }
  };

  const [selectedDate, setSelectedDate] = useState('');
  const handleRefresh = async () => {
    const options = { refresh: true };
    await getConsumer(options);
  };
  const renderSkeleton = () => (
    <>
      <AccountList>
        <AccountHeader>Email</AccountHeader>
        <IonItem>
          <IonSkeletonText animated style={{ height: '36px', width: '100%' }} />
        </IonItem>
      </AccountList>
    </>
  );

  return (
    <>
      <PageLayout
        showBack
        error={consumerError}
        loading={isLoadingConsumer}
        skeleton={renderSkeleton}
        onRefresh={handleRefresh}
        backRoute={routes.accountInfoBack}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <IonAlert
            isOpen={!!updateError}
            cssClass="modal-dark-mode"
            header="Error"
            message={updateError ? updateError.message : ''}
            buttons={['OK']}
          />
          <AccountList>
            <AccountHeader>Birthday</AccountHeader>
            <ErrorText color="danger">
              {updateError ? updateError.message : ''}
            </ErrorText>
            <IonItem className="web-hide">
              <IonDatetime
                name="birthday"
                value={selectedDate}
                onIonChange={(e) =>
                  setSelectedDate(moment(e.detail.value).format('YYYY-MM-DD'))
                }
              />
            </IonItem>
            <IonItem className="mobile-hide">
              <BirthdayField
                type="date"
                name="birthday"
                value={selectedDate}
                onChange={(e) =>
                  setSelectedDate(moment(e.target.value).format('YYYY-MM-DD'))
                }
                required
              />
            </IonItem>
            <ErrorText color="danger">
              {formErrors.birthday ? formErrors.birthday.message : ''}
            </ErrorText>
          </AccountList>
          <div className="modal-button">
            <SaveButton type="submit" disabled={isLoadingUpdate}>
              {isLoadingUpdate ? (
                <IonSpinner title="button-spinner" name="crescent" />
              ) : (
                'SAVE'
              )}
            </SaveButton>
          </div>
        </form>
      </PageLayout>
    </>
  );
};

export default ConsumerBirthdayPage;
