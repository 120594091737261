import { BaseMap } from '../../types/generic';
import { Campaign } from './campaigns-types';
import { campaignsActionTypes } from './campaigns-actions';
import { storeCampaignsActionTypes } from '../store-campaigns/store-campaigns-actions';
import { actionTypes } from '../actions/actions';
import { Action } from '../actions/actions-types';

export const campaignsInitialState = () => ({} as BaseMap<Campaign>);

export const campaignsReducer = (
  state = campaignsInitialState(),
  action: Action<Campaign[] | Campaign>
): any => {
  let newState: Partial<BaseMap<Campaign>> = {};

  switch (action.type) {
    case campaignsActionTypes.SET_CAMPAIGN:
      if (!Array.isArray(action.payload)) {
        newState = { ...state, [action.payload.id]: action.payload };
      }
      return newState;

    // TODO: check if necessary here.
    case storeCampaignsActionTypes.SET_STORE_CAMPAIGNS:
      if (Array.isArray(action.payload)) {
        action.payload.forEach((item: any) => {
          newState[item.id] = item;
        });
      }
      return newState;

    case actionTypes.CLEAR_STATE:
      return campaignsInitialState();

    default:
      return state;
  }
};
