import {
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonSearchbar,
  IonSkeletonText,
  IonThumbnail
} from '@ionic/react';
import { LDProps } from 'launchdarkly-react-client-sdk/lib/withLDConsumer';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import styled from 'styled-components';
import { useConsumerCardData } from '../features/consumer/consumer-resolver';
import { useGetConsumerStores } from '../features/consumer-stores/consumer-stores-resolver';
import emptyState from '../images/home-empty-state.svg';
import emptyStateV2 from '../images/home-empty-state-v2.svg';
import AddMoreBusinesses from '../components/AddMoreBusinesses';
import { useHomeNotifications } from '../features/notifications/notifications-resolver';
import NewUserAlerts from '../components/NewUserAlerts';
import { NoStoresEmptyState } from '../components/NoStoresEmptyState';
import { SectionHeader } from '../components/layout/SectionHeader';
import { PageLayout } from '../components/layout/PageLayout';
import { LinkAccountModal } from '../components/modals/LinkAccountModal';
import LinkCardModal from '../components/modals/LinkCardModal';
import { StoreSummary } from '../features/consumer-stores/consumer-stores-types';
import NoStoresEmptyStateV2 from '../components/NoStoresEmptyStateV2';
import { useReadyFlags } from '../utils/hooks';

const StoreHeader = styled.h2`
  font-size: 17px !important;
  font-weight: 600 !important;
`;

const StoreSubHeader = styled.h3`
  font-size: 14px;
`;

const StoreItem = styled(IonItem)`
  border-bottom: 1px var(--ion-color-light-shade) solid;
  --inner-border-width: 0px;
  --inner-padding-top: 34px;
  --inner-padding-bottom: 34px;

  ::part(detail-icon) {
    display: none;
  }
`;

const V2StoreItem = styled(IonItem)`
  --background-hover: none;
  ::part(detail-icon) {
    display: none;
  }
`;

const StoreThumbnail = styled(IonThumbnail)`
  width: 76px;
  height: 76px;
`;

const V2StoreThumbnail = styled(IonThumbnail)`
  width: 36px;
  height: 36px;

  --border-radius: 50%;
`;

const V2StoreSubHeader = styled(StoreSubHeader)`
  color: var(--ion-color-medium);
`;

const ItemLabel = styled(IonLabel)`
  margin: 0;
  padding: 10px 0;
`;

const renderSkeleton = () => (
  <IonList id="skeleton">
    <SectionHeader>
      <IonSkeletonText animated style={{ width: '70%' }} />
    </SectionHeader>
    {Array.from({ length: 4 }, (a, i) => (
      <StoreItem key={i}>
        <StoreThumbnail slot="start">
          <IonSkeletonText animated />
        </StoreThumbnail>
        <IonLabel>
          <h3>
            <IonSkeletonText animated style={{ width: '50%' }} />
          </h3>
          <p>
            <IonSkeletonText animated style={{ width: '70%' }} />
          </p>
        </IonLabel>
      </StoreItem>
    ))}
  </IonList>
);

const ConnectionsPage: React.FC<LDProps> = () => {
  const {
    ready: flagsReady,
    appV2 = false,
    enablePlaid = false
  } = useReadyFlags();
  const location = useLocation();
  const [filter, setFilter] = useState('');
  // check if wallet is in route currently
  const widgetPath = location.pathname.includes('widget');
  const storeLink = widgetPath ? '/widget/home/stores' : '/tabs/home/stores';

  const [
    { data: consumerStores, isLoading: loading, error: consumerStoresError },
    getConsumerStores
  ] = useGetConsumerStores();
  const [linkAccountModalOpen, setOpenLinkAccountModal] = useState(false);

  const { first: firstNotification } = useHomeNotifications();
  const cardData = useConsumerCardData();
  const showAlertInHomePage =
    !loading && consumerStores && consumerStores.length !== 1;

  useEffect(
    () => {
      getConsumerStores();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleRefresh = async () => {
    await getConsumerStores({ refresh: true });
  };

  const hasStores = !!consumerStores.length;
  const sortedStores = useMemo(
    () => [...consumerStores.filter(s => s.isSponsor), ...consumerStores.filter(s => !s.isSponsor)],
    [consumerStores]
  );

  const filteredStores = useMemo(() => {
    if (!filter) return sortedStores;
    const deb = _.deburr(filter);
    const reg = new RegExp(`(^${deb}.*$)|(^.+\\s${deb}.*$)`, 'i');
    return sortedStores.filter((store) => reg.test(store.name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const setFilterDebounced = _.debounce((value) => setFilter(value), 350);

  if (!flagsReady) return null;

  const oldStoreItems = (store: StoreSummary) => {
    return (
      <StoreItem
        key={store.id}
        routerLink={`${storeLink}/${store.id}`}
        color="light"
      >
        <StoreThumbnail slot="start">
          <IonImg src={store.logo} />
        </StoreThumbnail>
        <IonLabel>
          <StoreHeader>{store.name}</StoreHeader>
          <StoreSubHeader>{store.address}</StoreSubHeader>
        </IonLabel>
      </StoreItem>
    );
  };

  const v2StoreItems = (store: StoreSummary) => {
    return (
      <V2StoreItem key={store.id} routerLink={`${storeLink}/${store.id}`}>
        <V2StoreThumbnail slot="start">
          <IonImg src={store.logo} />
        </V2StoreThumbnail>
        <ItemLabel>
          <StoreHeader>{store.name}</StoreHeader>
          <V2StoreSubHeader>{store.address}</V2StoreSubHeader>
        </ItemLabel>
      </V2StoreItem>
    );
  };

  const emptyStateComponent = () => {
    return appV2 ? (
      <NoStoresEmptyStateV2 image={emptyStateV2} />
    ) : (
      <NoStoresEmptyState image={emptyState} />
    );
  };

  return (
    <PageLayout
      error={consumerStoresError}
      loading={loading}
      skeleton={renderSkeleton}
      onRefresh={handleRefresh}
      fullscreen={!hasStores}
    >
      <SectionHeader color="light">Your Plink Program</SectionHeader>
      {hasStores ? (
        <IonList>
          {appV2 ? (
            <>
              <IonSearchbar
                data-testid="filter-bar"
                value={filter}
                onIonChange={(e) => setFilterDebounced(e.detail.value!)}
              />
              {filteredStores.map(v2StoreItems)}
            </>
          ) : (
            sortedStores.map(oldStoreItems)
          )}

          <AddMoreBusinesses />
        </IonList>
      ) : (
        emptyStateComponent()
      )}

      {showAlertInHomePage && !appV2 && (
        <NewUserAlerts
          isLinkedOpen={firstNotification && Boolean(cardData.length)}
          isLinkCardOpen={firstNotification && !cardData.length}
          linkCardOptionalHandler={() => {
            setOpenLinkAccountModal(true);
          }}
        />
      )}

      {enablePlaid ? (
        <LinkAccountModal
          open={linkAccountModalOpen}
          closeHandler={() => setOpenLinkAccountModal(false)}
        />
      ) : (
        <LinkCardModal
          open={linkAccountModalOpen}
          closeModalHandler={() => setOpenLinkAccountModal(false)}
        />
      )}
    </PageLayout>
  );
};

export default ConnectionsPage;
