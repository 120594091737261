import React from 'react';
import styled from 'styled-components';
import { Transaction } from '../../../features/transactions/transactions-types';
import { TransactionItemReward } from './TransactionItemReward';

export interface TransactionItemRewardsProps {
  transaction: Transaction;
}

const TransactionRewardsPanel = styled.div`
  background: rgba(0, 203, 106, 0.3);
  width: 6.5rem;
  text-align: center;
  padding: 0.5rem;
  font-size: 0.85rem;
  border-radius: 4px;
  color: black;
`;

export const TransactionItemRewards: React.FC<TransactionItemRewardsProps> = ({
  transaction
}) => {
  const { campaigns } = transaction;
  if (!campaigns || !campaigns.length) return null;
  return (
    <TransactionRewardsPanel data-testid="transaction-item-reward">
      {campaigns.map((campaign) => (
        <TransactionItemReward transaction={transaction} campaign={campaign} />
      ))}
    </TransactionRewardsPanel>
  );
};
