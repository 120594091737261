import { yupResolver } from '@hookform/resolvers';
import {
  IonList,
  IonItem,
  IonListHeader,
  IonLabel,
  IonButton,
  IonSpinner,
  IonText,
  IonAlert,
  IonSkeletonText
} from '@ionic/react';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { PageLayout } from '../components/layout/PageLayout';

import {
  useConsumerData,
  useGetConsumer,
  usePatchConsumer
} from '../features/consumer/consumer-resolver';
import TextFieldInput from '../components/form/TextFieldInput';
import { FetchError } from '../features/fetch/fetch-types';
import { useAppState } from '../shared/AppContext';
import { updateConsumer } from '../features/consumer/consumer-actions';
import { routes } from '../shared/constants';

type FormValues = {
  firstName: 'string';
  lastName: 'string';
};

const AccountHeader = styled(IonListHeader)`
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: bold;
  font-size: 18pt;
`;

const SaveButton = styled(IonButton)`
  margin-left: 20px;
  margin-right: 20px;
`;

const ErrorText = styled(IonText)`
  padding-left: 20px;
`;

const AccountList = styled(IonList)`
  padding-right: 20px;
`;

const ConsumerNamePage: React.FC = () => {
  const { dispatch } = useAppState();
  const history = useHistory();
  const [
    { error: consumerError, isLoading: isLoadingConsumer },
    getConsumer
  ] = useGetConsumer();
  const consumerData = useConsumerData();
  const schema = yup.object().shape({
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required')
  });
  const { control, handleSubmit, errors: formErrors } = useForm<FormValues>({
    resolver: yupResolver(schema)
  });

  useEffect(
    () => {
      getConsumer();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [
    { isLoading: isLoadingUpdate, error: updateError },
    doPatchConsumer
  ] = usePatchConsumer({
    consumerId: consumerData.id
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const patchData = {
      consumerId: consumerData.id,
      ...data
    };
    const patchRes = await doPatchConsumer(patchData);
    if (patchRes && !(patchRes as FetchError).errorCode) {
      dispatch(updateConsumer(patchData));
      history.goBack();
    }
  };
  const handleRefresh = async () => {
    const options = { refresh: true };
    await getConsumer(options);
  };
  const renderSkeleton = () => (
    <>
      <AccountList>
        <AccountHeader>Name</AccountHeader>
        <IonItem>
          <IonLabel position="stacked">First</IonLabel>
          <IonSkeletonText animated style={{ height: '36px', width: '100%' }} />
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Last</IonLabel>
          <IonSkeletonText animated style={{ height: '36px', width: '100%' }} />
        </IonItem>
      </AccountList>
    </>
  );

  return (
    <>
      <PageLayout
        showBack
        error={consumerError}
        loading={isLoadingConsumer}
        onRefresh={handleRefresh}
        skeleton={renderSkeleton}
        backRoute={routes.accountInfoBack}
      >
        <IonAlert
          isOpen={!!updateError}
          cssClass="modal-dark-mode"
          header="Error"
          message={updateError ? updateError.message : ''}
          buttons={['OK']}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <AccountList>
            <AccountHeader>Name</AccountHeader>
            <ErrorText color="danger">
              {updateError ? updateError.message : ''}
            </ErrorText>
            <IonItem>
              <IonLabel position="stacked">First</IonLabel>
              <TextFieldInput
                type="text"
                name="firstName"
                control={control}
                value={consumerData.firstName}
                inputClass="none"
              />
            </IonItem>
            <ErrorText color="danger">
              {formErrors.firstName && formErrors.firstName.message}
            </ErrorText>
            <IonItem>
              <IonLabel position="stacked">Last</IonLabel>
              <TextFieldInput
                type="text"
                name="lastName"
                control={control}
                value={consumerData.lastName}
                inputClass="none"
              />
            </IonItem>
            <ErrorText color="danger">
              {formErrors.lastName && formErrors.lastName.message}
            </ErrorText>
          </AccountList>
          <div className="modal-button">
            <SaveButton type="submit" disabled={isLoadingUpdate}>
              {isLoadingUpdate ? (
                <IonSpinner title="button-spinner" name="crescent" />
              ) : (
                'SAVE'
              )}
            </SaveButton>
          </div>
        </form>
      </PageLayout>
    </>
  );
};

export default ConsumerNamePage;
