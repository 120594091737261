import { ActionCreator } from '../actions/actions-types';

export const walletActionTypes = {
  SET_WALLET: 'setWallet',
  ADD_WALLET: 'addWallet',
  REMOVE_WALLET: 'removeWallet'
};

export const setWallet: ActionCreator = (payload) => ({
  type: walletActionTypes.SET_WALLET,
  payload
});

export const addWallet: ActionCreator = (payload) => ({
  type: walletActionTypes.ADD_WALLET,
  payload
});

export const removeWallet: ActionCreator = (payload) => ({
  type: walletActionTypes.REMOVE_WALLET,
  payload
});
