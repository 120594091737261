import { ActionCreator } from '../actions/actions-types';

export const campaignsActionTypes = {
  SET_CAMPAIGN: 'setCampaign'
};

export const setCampaign: ActionCreator = (payload) => ({
  type: campaignsActionTypes.SET_CAMPAIGN,
  payload
});
