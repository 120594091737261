import React, { useState } from 'react';
import {
  IonRefresher,
  IonRefresherContent,
  useIonViewDidEnter,
  useIonViewWillLeave
} from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import { chevronDownCircleOutline } from 'ionicons/icons';

export const Refresher: React.FC<{
  onRefresh: () => Promise<any>;
}> = ({ onRefresh }) => {
  const [showRefresher, setRefresher] = useState(false);

  useIonViewDidEnter(() => {
    setRefresher(true);
  });

  useIonViewWillLeave(() => {
    setRefresher(false);
  });

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    await onRefresh();
    event.detail.complete();
  };

  return (
    <>
      {showRefresher && (
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent pullingIcon={chevronDownCircleOutline} />
        </IonRefresher>
      )}
    </>
  );
};

export default Refresher;
