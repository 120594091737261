import Loadable from 'react-loadable';

export const ForgotPasswordPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "forgot-password-page" */
      /* webpackMode: "lazy" */
      './ForgotPasswordPage'
    ),
  loading: () => null
});
