import React from 'react';
import { IonLabel } from '@ionic/react';
import styled from 'styled-components';
import { links } from '../../shared/constants';

export const FanbankTermsLink: React.FC = () => (
  <a
    title="Fanbank Terms and Conditions Link"
    target="_blank"
    rel="noopener noreferrer"
    href={links.terms}
  >
    Terms of Service
  </a>
);

export const FanbankPrivacyPolicyLink: React.FC = () => (
  <a
    title="Privacy Policy Link"
    target="_blank"
    rel="noopener noreferrer"
    href={links.privacy}
  >
    Privacy Policy
  </a>
);

const VisaAgreement = () => (
  <>
    I agree that each time I make a purchase or return with this card at a
    merchant with Fanbank offers, Visa may share details of that transaction
    (such as the date, time, and amount) with Fanbank, its service provider,
    Collinson CLO, Inc., and participating merchants. I confirm that I am at
    least 18 years of age. I have read and I agree to Fanbank&apos;s{' '}
    <FanbankTermsLink />, <FanbankPrivacyPolicyLink /> and authorize Fanbank and
    Collinson CLO, Inc. to use and share my data to enable cardlinked offers,
    calculate points, present me with offers and facilitate the Fanbank program,
    as described therein. I may opt-out of transaction monitoring on the payment
    card(s) I entered at any time by navigating to Card Management and removing
    the registered card.
  </>
);

const AmericanExpressAgreement: React.FC = () => (
  <>
    I agree that each time I make a purchase or return with this card at a
    merchant with Fanbank offers, American Express may share details of that
    transaction (such as the date, time, and amount) with Fanbank, its service
    provider, Collinson CLO, Inc., and participating merchants. I confirm that I
    am at least 18 years of age. I have read and I agree to the Fanbank I have
    read and I agree to the Fanbank <FanbankTermsLink /> and{' '}
    <FanbankPrivacyPolicyLink /> and authorize Fanbank and Collinson CLO, Inc.
    to use and share my data to enable cardlinked offers, calculate points,
    present me with offers and facilitate the Fanbank program, as described
    therein. I may opt-out of transaction monitoring on the payment card(s) I
    entered at any time by clicking &quot;X&quot; against each card registered.
  </>
);

const MasterCardAgreement: React.FC = () => (
  <>
    By checking this box and registering my payment card, I confirm that I am 18
    years of age and authorize Mastercard to monitor activity on my linked
    payment card(s) to identify qualifying transactions and for Mastercard to
    share details of that transaction (such as the date, time, and amount) with
    Fanbank, its service provider, Collinson CLO, Inc., and participating
    merchants in accordance with Fanbank’s <FanbankTermsLink /> and{' '}
    <FanbankPrivacyPolicyLink />. Collinson CLO, Inc. monitors transactions on
    your registered payment card(s) to identify qualifying purchases in order to
    determine whether you have qualified for, or earned, rewards linked to your
    payment card. I understand I may opt-out of transaction monitoring on the
    payment card(s) I entered at any time by visiting &quot;Card
    Management&quot; and clicking the &quot;X&quot; next to the registered
    payment card I wish to remove.
  </>
);

const AcceptTermsText = styled(IonLabel)`
  --color: #7b7b7b;
  font-size: 12px;
  min-width: 287px;
  margin-left: 13px;
`;

const copy = (card: string) => {
  if (card === 'american-express') return <AmericanExpressAgreement />;
  if (card === 'mastercard') return <MasterCardAgreement />;
  return <VisaAgreement />;
};

const WaiverText: React.FC<{ card: string }> = ({ card }) => {
  return <AcceptTermsText>{copy(card)}</AcceptTermsText>;
};

export default WaiverText;
