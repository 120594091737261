import { ActionCreator } from '../actions/actions-types';

export const consumerActionTypes = {
  SET_CONSUMER: 'setConsumer',
  SET_CONSUMER_CARD: 'setConsumerCard',
  UPDATE_CONSUMER: 'updateConsumer'
};

export const setConsumer: ActionCreator = (payload) => ({
  type: consumerActionTypes.SET_CONSUMER,
  payload
});

export const setConsumerCard: ActionCreator = (payload) => ({
  type: consumerActionTypes.SET_CONSUMER_CARD,
  payload
});

export const updateConsumer: ActionCreator = (payload) => ({
  type: consumerActionTypes.UPDATE_CONSUMER,
  payload
});
