import React from 'react';
import styled from 'styled-components';
import { IonIcon, IonRouterLink } from '@ionic/react';
import { informationCircle } from 'ionicons/icons';

export interface SectionHeaderProps
  extends React.HTMLAttributes<HTMLHeadingElement> {
  icon?: string;
  href?: string;
  children: React.ReactNode;
}

const Link = styled(IonRouterLink)`
  vertical-align: middle;
  // TODO: use rem instead of px
  padding: 0 0 0 10px;
`;

const Container = styled.h1`
  font-weight: bold;
  font-size: 22px;
  padding: 0 20px;
`;

const Icon = styled(IonIcon)`
  color: #666666;
`;

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  href,
  icon = informationCircle,
  children,
  ...props
}) => {
  return (
    <Container {...props}>
      {children}
      {href && (
        <Link href={href} className="info-circle">
          <Icon icon={icon} />
        </Link>
      )}
    </Container>
  );
};
