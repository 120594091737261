import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { LDProps } from 'launchdarkly-react-client-sdk/lib/withLDConsumer';
import React, { useEffect } from 'react';
import {
  IonItem,
  IonSkeletonText,
  IonList,
  IonLabel,
  IonListHeader,
  IonThumbnail
} from '@ionic/react';
import styled from 'styled-components';
import { useGetConsumer } from '../features/consumer/consumer-resolver';
import { useGetConsumerStores } from '../features/consumer-stores/consumer-stores-resolver';
import ConnectionsPage from './ConnectionsPage';
import { StorePage } from './StorePage';

const StoreItem = styled(IonItem)`
  --inner-padding-top: 34px;
  --inner-padding-bottom: 34px;
`;

const StoreThumbnail = styled(IonThumbnail)`
  width: 76px;
  height: 76px;
`;

const renderSkeleton = () => {
  return (
    <IonList id="skeleton">
      <IonListHeader>
        <IonSkeletonText animated style={{ width: '70%' }} />
      </IonListHeader>
      {Array.from({ length: 4 }, (a, i) => (
        <StoreItem key={i} lines="full">
          <StoreThumbnail slot="start">
            <IonSkeletonText animated />
          </StoreThumbnail>
          <IonLabel>
            <h3>
              <IonSkeletonText animated style={{ width: '50%' }} />
            </h3>
            <p>
              <IonSkeletonText animated style={{ width: '70%' }} />
            </p>
          </IonLabel>
        </StoreItem>
      ))}
    </IonList>
  );
};

const HomePage: React.FC<LDProps> = ({ flags }) => {
  const [{ isLoading: isLoadingConsumer }, getConsumer] = useGetConsumer();
  const [
    { data: consumerStores, isLoading: isLoadingConsumerStores },
    getConsumerStores
  ] = useGetConsumerStores();

  useEffect(
    () => {
      getConsumer();
      getConsumerStores();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      {isLoadingConsumer || isLoadingConsumerStores ? renderSkeleton() : ''}

      {!isLoadingConsumer &&
        !isLoadingConsumerStores &&
        (!flags?.appV2 && consumerStores.length === 1 ? (
          <StorePage storeId={consumerStores[0].id} showBack={false} />
        ) : (
          <ConnectionsPage />
        ))}
    </>
  );
};

export default withLDConsumer()(HomePage);
