import { ActionCreator } from '../actions/actions-types';

export const authActionTypes = {
  SET_AUTH: 'setAuth'
};

export const setAuth: ActionCreator = (payload) => ({
  type: authActionTypes.SET_AUTH,
  payload
});
