import React from 'react';
import AlertWithImage from './AlertWithImage';
import walletLock from '../images/wallet-lock.svg';
import walletStar from '../images/wallet-star.svg';
import { useAppState } from '../shared/AppContext';
import { setNotificationHome } from '../features/notifications/notifications-actions';

type AlertProps = {
  isLinkedOpen: boolean;
  isLinkCardOpen: boolean;
  linkCardOptionalHandler: () => void;
};

const NewUserAlerts: React.FC<AlertProps> = ({
  isLinkedOpen,
  isLinkCardOpen,
  linkCardOptionalHandler
}) => {
  const { dispatch } = useAppState();

  if (isLinkedOpen) {
    return (
      <AlertWithImage
        open={isLinkedOpen}
        header="Success!"
        alertMessage="Your payment card is linked and every offer is unlocked and available for you to add to your wallet."
        image={walletStar}
        buttons={[
          {
            text: 'Get Started',
            role: 'cancel',
            handler: () => {
              dispatch(setNotificationHome(false));
            }
          }
        ]}
      />
    );
  }

  return (
    <AlertWithImage
      open={isLinkCardOpen}
      header="Link Card to Unlock"
      alertMessage="All offer tracking and fulfillment takes place on your existing payment card."
      image={walletLock}
      buttons={[
        {
          text: 'Link Card',
          handler: () => {
            dispatch(setNotificationHome(false));
            if (linkCardOptionalHandler) {
              linkCardOptionalHandler();
            }
          }
        },
        {
          text: 'Not Now',
          role: 'cancel',
          cssClass: 'alert-cancel-button',
          handler: () => {
            dispatch(setNotificationHome(false));
          }
        }
      ]}
    />
  );
};

export default NewUserAlerts;
