import { transactionsActionTypes } from './transactions-actions';
import { Transaction } from './transactions-types';
import { PaginatedResponse } from '../../types/api';
import { actionTypes } from '../actions/actions';
import { Action } from '../actions/actions-types';

export const transactionsInitialState = () =>
  ({
    data: [],
    pagination: {
      page: 0,
      last: 0,
      limit: 0,
      total: 0
    }
  } as PaginatedResponse<Transaction>);

export const transactionsReducer = (
  state = transactionsInitialState(),
  action: Action<PaginatedResponse<Transaction>>
): any => {
  switch (action.type) {
    case transactionsActionTypes.SET_TRANSACTIONS: {
      const { pagination, data } = action.payload;
      if (pagination.page === 1) {
        // setting the first page
        return { ...state, data, pagination };
      }
      if (pagination.page <= pagination.last) {
        // appending next transactions page
        return { ...state, data: [...state.data, ...data], pagination };
      }
      return state;
    }
    case actionTypes.CLEAR_STATE:
      return transactionsInitialState();

    default:
      return state;
  }
};
