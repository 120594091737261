import React from 'react';
import styled from 'styled-components';
import { PlaidToken } from '../../../features/plaid/plaid-types';
import { PlaidItemMenu } from './PlaidItemMenu';

interface Props {
  token: PlaidToken;
}

const PlaidTokenDtStyle = styled.dt`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 48px 0 12px 0;
  display: flex;

  &:first-child {
    margin-top: 0;
  }

  > div {
    display: flex;
    align-items: center;
  }

  .account {
    flex-grow: 1;
  }
`;

const PlaidTokenDdStyle = styled.dd`
  margin: 0;
  border-bottom: 1px solid #333;
  padding: 12px 0;

  p {
    margin: 0;

    &.mask {
      margin-top: 8px;
      opacity: 0.5;
    }
  }

  &.error p {
    color: red;
  }

  &.error-caption {
    border-bottom: 0;
    opacity: 0.5;
  }
`;

export const PlaidItem = ({ token }: Props) => (
  <>
    <PlaidTokenDtStyle>
      <div className="account">{token.name}</div>
      <div className="button">
        <PlaidItemMenu token={token} />
      </div>
    </PlaidTokenDtStyle>

    {(token.accounts || []).map(({ accountId, name, mask }) => (
      <PlaidTokenDdStyle
        className={token.hasError ? 'error' : ''}
        key={accountId}
      >
        <p className="account">{name}</p>
        <p className="mask">{`${new Array(12 - mask.length)
          .fill('*')
          .join('')}${mask}`}</p>
      </PlaidTokenDdStyle>
    ))}

    {Boolean(token.hasError) && (
      <PlaidTokenDdStyle className="error error-caption">
        <p>A connection issue has occurred. Please relink your accounts.</p>
      </PlaidTokenDdStyle>
    )}
  </>
);
