import React from 'react';
import Loadable from 'react-loadable';
import { useReadyFlags } from '../../utils/hooks';

const OnboardingV1 = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "onboarding-v1" */
      /* webpackMode: "lazy" */
      './OnboardingPageV1'
    ),
  loading: () => null
});
const OnboardingV2 = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "onboarding-v2" */
      /* webpackMode: "lazy" */
      './OnboardingPageV2'
    ),
  loading: () => null
});

export const Onboarding: React.FC = () => {
  const flags = useReadyFlags();
  const { loading, appV2, enablePlaid } = flags;
  if (loading) return null;
  else if (appV2 && enablePlaid) return <OnboardingV2 />;
  else return <OnboardingV1 />;
};
