import { ActionCreator } from '../actions/actions-types';

export const consumerStoresActionTypes = {
  SET_CONSUMER_STORES: 'setConsumerStores'
};

export const setConsumerStores: ActionCreator<Required<string>> = (
  payload
) => ({
  type: consumerStoresActionTypes.SET_CONSUMER_STORES,
  payload
});
