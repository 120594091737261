import { useGet, usePost, usePatch } from '../fetch/fetch-resolver';
import { setCardToken } from '../../shared/Actions';
import { useAuth } from '../auth/auth-resolver';
import {
  CardDetail,
  Consumer,
  ConsumerRegistrationRequest,
  CreateCardTokenRequest,
  CreateConsumerCardRequest,
  ConsumerPatchRequest
} from './consumer-types';
import { useAppState } from '../../shared/AppContext';
import {
  setConsumer,
  setConsumerCard,
  updateConsumer
} from './consumer-actions';

export const useGetConsumer = (storeId?: number) => {
  const auth = useAuth();
  const url = storeId
    ? 'consumers/:consumerId?storeId=:storeId'
    : 'consumers/:consumerId';
  return useGet<Consumer>({
    url,
    version: 'v2',
    initialParams: { consumerId: auth.consumerId || auth.userId, storeId },
    action: setConsumer,
    selector: (state) => state.consumer
  });
};

export const usePostConsumer = (request?: ConsumerRegistrationRequest) => {
  const url = 'consumers';
  return usePost<ConsumerRegistrationRequest>({
    url,
    version: 'v2',
    initialData: request,
    action: setConsumer
  });
};

export const usePatchConsumer = (request: ConsumerPatchRequest) => {
  const url = `consumers/${request.consumerId}`;

  return usePatch<ConsumerPatchRequest>({
    url,
    version: 'v2',
    initialData: request,
    action: updateConsumer
  });
};

export const useConsumerCardData = (): CardDetail[] => {
  const { state } = useAppState();
  return state.consumer && state.consumer.cardData
    ? state.consumer.cardData
    : [];
};

export const useConsumerData = (): Consumer => {
  const { state } = useAppState();
  return state.consumer || {};
};

// TODO: create a feature?
export const usePostCardToken = (request?: CreateCardTokenRequest) => {
  const url = 'card-tokens';
  return usePost<CreateCardTokenRequest>({
    url,
    initialData: request,
    action: setCardToken,
    client: 'tokenService'
  });
};

export const usePostConsumerCard = (request?: CreateConsumerCardRequest) => {
  const url = 'api/consumer-cards';
  return usePost<CreateConsumerCardRequest>({
    url,
    initialData: request,
    action: setConsumerCard
  });
};
