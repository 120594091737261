import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useAuth } from '../features/auth/auth-resolver';
import { useAppState } from '../shared/AppContext';
import { clearState } from '../features/actions/actions';

const LogoutPage: React.FC = () => {
  const { dispatch } = useAppState();
  const auth = useAuth();

  useEffect(() => {
    dispatch(clearState());
  }, [dispatch, auth]);

  return <>{isEmpty(auth) && <Redirect to="/login" />}</>;
};

export default LogoutPage;
